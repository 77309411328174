import React, { useEffect } from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import EstimatePlanner from 'components/shared/estimate/estimate-planner'
import EstimateSummary from 'components/shared/estimate/estimate-summary'
import PriceLabel from 'components/shared/estimate/price-label'
import useEstimatePlanner from 'context/estimate/use-estimate-planner'
import useProspect from 'context/prospect/use'
import Button from 'design-system/components/button'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { NodeRelationshipGraph } from 'planner/compose'
import { WhatsIncluded } from 'planner/utils/volume-utils'
import { Breakpoint, Colors } from 'styles/app/system'
import { TemplateUpgrades } from 'types/volume'

type BudgetProps = Record<string, unknown>

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0, // will print 2500.10 as $2,500.1 and whole numbers correctly
  maximumFractionDigits: 0, // causes 2500.99 to be printed as $2,501)
})

const upgradesLabelMap: Record<TemplateUpgrades, string> = {
  [TemplateUpgrades.$]: 'Essentials',
  [TemplateUpgrades.$$]: 'Premium',
  [TemplateUpgrades.$$$]: 'Luxury',
}

const getPriceFromUpgrades = (
  upgrades?: Record<TemplateUpgrades, WhatsIncluded>,
) => {
  return Object.keys(upgrades || {})?.map((u) => {
    const { price } = upgrades?.[u as TemplateUpgrades]
      ? upgrades[u as TemplateUpgrades]
      : { price: 0 }

    return {
      label: upgradesLabelMap[u as TemplateUpgrades],
      price: numberFormatter.format(price / 100), // Price comes in cents, so we need to show it in full dollars
    }
  })
}

const Budget = (_props: BudgetProps) => {
  const isMobile = useMedia(Breakpoint.downFromComputer)
  const { prospect } = useProspect()
  const { upgrades, updateUpgrades, loading } = useEstimatePlanner(prospect)
  const estimate = prospect?.data?.estimate

  useEffect(() => {
    if (!loading) updateUpgrades()
  }, [loading])

  const prices = estimate ? getPriceFromUpgrades(upgrades) : null

  return (
    <Section id="budget">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column tablet={16} computer={8}>
            <p className="subtitle light-gray">Budget</p>
            <h3 className="gray">
              Do you have a sense of what you were hoping to spend on cabinetry?
            </h3>
          </Grid.Column>

          {isMobile ? <Spacer height={50} /> : null}

          <Grid.Column tablet={16} computer={8}>
            {!loading ? (
              <EstimateContainer fixedWidth={!estimate}>
                {estimate ? (
                  <EstimateData prices={prices ?? []} />
                ) : (
                  <EstimatePlaceholder />
                )}
              </EstimateContainer>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

const EstimatePlaceholder = () => {
  const { prospect } = useProspect()

  return (
    <PlaceholderContainer>
      <h4 className="gray" style={{ marginTop: 'auto' }}>
        No estimate yet
      </h4>
      <p className="light-gray" style={{ marginBottom: 'auto' }}>
        Click below to create your estimate
      </p>

      <Button
        kind="solid"
        fontAwesomeIcon="arrow-right"
        style={{ marginTop: 'auto' }}
        text="Create Estimate"
        onClick={() => window.open(`/welcome/${prospect?.id}/estimate/layout`)}
        size="large"
      />
    </PlaceholderContainer>
  )
}

const EstimateData = ({
  prices,
}: {
  prices: {
    label: string
    price: string
  }[]
}) => {
  const { prospect } = useProspect()
  const {
    sceneRelationshipGraph,
    sceneState,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    loadingUpgrades,
  } = useEstimatePlanner(prospect)

  return (
    <>
      <PlannerContainer>
        <EstimatePlanner
          sceneRelationshipGraph={
            sceneRelationshipGraph as NodeRelationshipGraph
          }
          sceneState={sceneState}
          viewBoxBase={viewBoxBase}
          viewBoxPanX={viewBoxPanX}
          viewBoxPanY={viewBoxPanY}
        />
      </PlannerContainer>
      {!loadingUpgrades && (
        <PriceDetails>
          {prices?.map((p) => (
            <div key={p.label}>
              <PriceLabel centered>
                <span>$</span>
                <h3 className="no-margin">{p.price}</h3>
              </PriceLabel>
              <p className="overline light-gray">{p.label}</p>
            </div>
          ))}
        </PriceDetails>
      )}
      <EstimateSummary withMargin={false} />

      <StyledButton
        kind="solid"
        fontAwesomeIcon="redo"
        text="Update Estimate"
        onClick={() =>
          navigate(
            `/welcome/${prospect?.id}/estimate/results?backTo=free-consultation`,
          )
        }
        iconPosition="left"
        size="large"
      />
    </>
  )
}

export default Budget

const StyledButton = styled(Button)`
  display: block;
  margin: 20px auto;
`

const PlannerContainer = styled.div`
  margin: 0 auto;
  max-width: 400px;
`

const PriceDetails = styled.div`
  align-items: center;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 30px 0;
  text-align: center;

  div {
    flex: 1;

    h4 {
      margin-top: 5px;
    }

    h2 {
      margin: 0 auto;
    }
  }
`

const EstimateContainer = styled.div<{ fixedWidth?: boolean }>`
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0px 10px 15px rgba(120, 113, 108, 0.09);
  padding: 16px 24px;

  ${(props) =>
    props.fixedWidth &&
    css`
      margin: 0 auto;
      max-width: 350px;
    `}
`

const PlaceholderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  padding-top: 50px;
  place-content: center;
`
