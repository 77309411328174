import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

import { BasicPlaceholder } from 'components/shared/file-placeholder'
import { CardLoader } from 'components/shared/loader'
import { isImageFile } from 'context/project-file/utils'
import { Breakpoint, Colors } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'
import * as viewUtils from 'views/utils'

interface FileCardProps {
  file?: Partial<ProjectFile>
  onFileRemoved?: (id?: string) => Promise<void>
  removeDisabled?: boolean
}

const FileCard = ({ file, onFileRemoved, removeDisabled }: FileCardProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const fileName = file?.name
  let fileKey = file?.key
  if (!isImageFile(file?.name ?? '')) fileKey = 'placeholder'

  return (
    <div>
      {loading && (
        <CardLoader active={loading}>
          <p>Deleting</p>
        </CardLoader>
      )}
      <MainContainer
        fluid
        style={{
          opacity: `${loading ? '0.3' : '1'}`,
        }}
      >
        <Card.Content className="file" textAlign="center">
          {fileKey === 'placeholder' ? (
            <BasicPlaceholder />
          ) : (
            <ImageCard
              image={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                String(fileKey),
              )}`}
            />
          )}
        </Card.Content>
        <Card.Content className="file-info">
          <p>{viewUtils.responsiveText(fileName ?? '', 19)}</p>
        </Card.Content>
        <span
          onClick={async () => {
            setLoading(true)
            await onFileRemoved?.(file?.id)
            setLoading(false)
          }}
        >
          <DeleteIcon icon={['fal', 'times']} disabled={removeDisabled} />
        </span>
      </MainContainer>
    </div>
  )
}

export default FileCard

const DeleteIcon = styled(FontAwesomeIcon)<{ disabled?: boolean }>`
  &&& {
    background: ${(props) =>
      props.disabled ? Colors.gray300 : Colors.gray800};
    border-radius: 50%;
    color: ${Colors.white};
    cursor: pointer;
    font-size: 15px;
    height: 16px;
    padding: 4px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 16px;
    z-index: 1;

    :hover {
      transition: all 0.3s ease-in-out;
      background: ${(props) =>
        props.disabled ? Colors.gray300 : Colors.gray500};
    }

    @media ${Breakpoint.onlyMobile} {
      right: 25px;
      top: 25px;
    }
  }
`

const MainContainer = styled(Card)`
  &&& {
    border-radius: 8px;
    box-shadow: none;
    color: ${Colors.black};
    display: block;
    margin: 0;
    min-width: 200px !important;
    padding: 0;
    width: 100%;

    div.file {
      display: flex;
      justify-content: center;
      padding: 0;
      position: relative;
    }

    div.file-info {
      background: ${Colors.gray200};
      border: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 6px 20px 10px;

      p {
        margin: 0;
      }

      p.file-type {
        color: ${Colors.black};
      }
    }

    :hover {
      cursor: default;
      transform: translateY(0);
    }
  }
`

const ImageCard = styled.div<{ image: string }>`
  background: url('${(props) => props.image}') no-repeat center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  margin: 0;
  min-height: 180px;
  width: 100% !important;
`
