import React from 'react'

import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'

import Carousel from 'design-system/components/carousel'
import StepCard from 'design-system/components/guide/step-card'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const stepsFORM = [
  {
    title: 'Initial Design',
    description:
      'This step is about giving you what you need to make an informed decision on whether to move forward or not.',
    step: 1,
    subtitle: '$299 Design Deposit',
    listItems: [
      'Two 1 hour consultations (Typically 10 hours of design work)',
      '3 full-size samples delivered to your door',
      'Set of photorealistic visuals',
      'Visual quote with pricing',
    ],
  },
  {
    title: 'Refine',
    description:
      'We will work with you to perfect the design and finalize the details. We can also add cabinetry for other areas of the home, there may be a small deposit required.',
    step: 2,
    subtitle: '$1,999 Project Deposit',
    listItems: [
      'Detailed kitchen plans with itemized pricing',
      'Regular design consultations (Typically 20 - 60 hours of design work)',
      '3 revisions included (Extra or complex revisions $199 each)',
      'Option to add additional rooms',
    ],
  },
  {
    title: 'Deliver',
    description:
      'Our customer success team is on hand to support you every step of the way',
    step: 3,
    subtitle: 'Balance Payment',
    listItems: [
      'Fully assembled cabinetry with award-winning hardware',
      '25 year manufacturing warranty',
      'Free shipping to contiguous US',
      'Set of final photorealistic visuals',
      'Installation materials and resources',
      'Unlimited support from our best-in-class customer success team',
    ],
  },
]

const stepSlides = stepsFORM.map((s) => ({
  id: s.step,
  item: (
    <div style={{ paddingTop: 40, height: '100%', paddingBottom: 20 }}>
      <StepCard {...s} />
    </div>
  ),
}))

const HowWeWork = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <Section id="how-we-work">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">How does FORM work?</h3>
          </Grid.Column>
        </Grid.Row>
        <Spacer height={50} />
        {isDownFromComputer ? (
          <Carousel
            slides={stepSlides}
            hideNavButtons
            slideSize={isMobile ? 80 : 50}
          />
        ) : (
          <Grid.Row columns={16}>
            {stepsFORM.map((step) => (
              <Grid.Column key={step.title} width={5}>
                <StepCard {...step} />
              </Grid.Column>
            ))}
          </Grid.Row>
        )}
      </Grid>
    </Section>
  )
}

export default HowWeWork
