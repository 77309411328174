import React, { useCallback, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { FileRejection, useDropzone } from 'react-dropzone'
import sanitize from 'sanitize-filename'

import { CardLoader } from 'components/shared/loader'
import { ProjectFileCard } from 'components/shared/project/project-file/upload-card'
import { apolloClient } from 'context/authorized-apollo/provider'
import { convertHeicToJpeg } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import {
  apiResultToAWSFormData,
  GET_CRM_FILE_UPLOAD_URL,
  GetCrmFileUploadUrlPayload,
  GetCrmFileUploadUrlVariables,
} from 'queries/aws'
import {
  CREATE_PROJECT_FILE,
  CreateProjectFilePayload,
  CreateProjectFileVariables,
} from 'queries/project-file'
import { ProjectFile, ProjectFileType } from 'types/project-file'

interface UploadFileCardProps {
  fileType?: ProjectFileType
  onFilesUploaded: (files: string[]) => void
  prospectId: string
}

const UploadFileCard = ({
  fileType,
  onFilesUploaded,
  prospectId,
}: UploadFileCardProps) => {
  return (
    <UploadCard
      onFilesUploaded={onFilesUploaded}
      uploadFile={async (file) =>
        await uploadImage({
          imgFile: file,
          prospectId,
          fileType,
        })
      }
    />
  )
}

export default UploadFileCard

const UploadCard = ({
  acceptFiles = '.jpg, .jpeg, .png, .heic, .tiff, .tif, .pdf, .webp, .doc, .docx, .ppt, .pptx',
  onFilesUploaded,
  uploadFile,
}: {
  acceptFiles?: string
  onFilesUploaded?: (files: string[]) => void
  uploadFile: (file: File) => Promise<any>
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fileRejection, setFileRejection] = useState<string>('')

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setFileRejection('')
      setLoading(true)
      if (fileRejections.length) {
        setFileRejection('Please only upload PDFs or images')
      }
      const uploadImagePromises = []
      for (const file of acceptedFiles) {
        uploadImagePromises.push(uploadFile(file))
      }
      const fkeys = await Promise.all(uploadImagePromises)
      setLoading(false)
      onFilesUploaded?.(fkeys)
    },
    [],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFiles,
    disabled: loading,
  })
  return (
    <>
      <ProjectFileCard
        {...getRootProps()}
        styled={{
          isDragActive: isDragActive,
          disabled: loading,
        }}
      >
        {loading && (
          <CardLoader active={loading}>
            <p>Uploading</p>
          </CardLoader>
        )}
        <div className="content">
          <FontAwesomeIcon icon={['fal', 'image-polaroid']} />
          <div className="only-computer">
            <p>Drag a file here or</p>
          </div>
          <div className="down-computer">
            <p>Add file here</p>
          </div>
          <Button
            disabled={loading || isDragActive}
            color="dark"
            kind="solid"
            size="fluid"
            text="Select files"
          />
        </div>
        <input
          disabled={loading}
          hidden
          multiple
          type="file"
          {...getInputProps()}
        />
      </ProjectFileCard>
      {fileRejection && (
        <div style={{ marginTop: 15 }}>
          <p>
            <i className="red">{fileRejection}</i>
          </p>
        </div>
      )}
    </>
  )
}

export const uploadImage = async ({
  imgFile,
  prospectId,
  fileType,
}: {
  imgFile: File
  prospectId: string
  fileType?: ProjectFileType
}) => {
  let file = imgFile
  if (
    file.type === 'image/heic' ||
    file.name.toLocaleLowerCase().endsWith('heic')
  )
    file = await convertHeicToJpeg(imgFile)
  const fileRemovedSpecialCharacters = file.name.replace(
    /[^a-zA-Z0-9.\-_]/g,
    '',
  )
  const fileNameSanitized = sanitize(fileRemovedSpecialCharacters)

  let url = ''
  let dataFields: Record<string, any> = {}

  const res = await apolloClient?.mutate<
    GetCrmFileUploadUrlPayload,
    GetCrmFileUploadUrlVariables
  >({
    mutation: GET_CRM_FILE_UPLOAD_URL,
    variables: {
      data: { fname: fileNameSanitized, prospectId },
    },
  })
  url = res?.data?.uploadUrlCrmFile.url ?? ''
  dataFields = apiResultToAWSFormData(res?.data?.uploadUrlCrmFile ?? {})

  const data = new FormData()
  Object.keys(dataFields).forEach((k) => {
    data.append(k, dataFields[k])
  })
  data.append('acl', 'public-read')
  data.append('Content-Type', file.type)
  data.append('file', file)

  const uploadRes = await axios.post(url, data)

  if (uploadRes.status === 204) {
    const projectfileRes = await apolloClient?.mutate<
      CreateProjectFilePayload,
      CreateProjectFileVariables
    >({
      mutation: CREATE_PROJECT_FILE,
      variables: {
        data: {
          key: dataFields.key,
          name: fileNameSanitized,
          prospect: prospectId ? { connect: { id: prospectId } } : undefined,
          type: fileType || ProjectFileType.PROSPECT_PHOTO,
          url,
        },
      },
    })
    return projectfileRes?.data?.createOneProjectFile as ProjectFile
  }
  throw new Error(
    `[${uploadRes.status}] Upload image failed: ${uploadRes.statusText}`,
  )
}
