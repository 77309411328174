import React, { useContext } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { omit } from 'lodash'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMedia } from 'react-use'
import { Container, Form, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import EstimatorStepLayout from 'components/welcome/estimate/step-layout'
import useEstimate from 'context/estimate/use'
import LeadTimeProvider from 'context/lead-times/provider'
import { validateAffiliateCode } from 'context/project/utils-discounts'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import fireEvent from 'context/tracking/events'
import {
  getShareASaleClickId,
  TrackingContext,
} from 'context/tracking/provider'
import { useZipCodes } from 'context/zip-codes/provider'
import Button from 'design-system/components/button'
import { ControlledPhoneInput } from 'design-system/components/form'
import { Breakpoint } from 'styles/app/system'
import { StyledForm } from 'styles/welcome/components/form'
import { FormContactSource } from 'types/crm'
import { emailIsValid } from 'views/utils'

import { CssOverride } from '../utils'

type ProspectInformationForm = {
  email: string
  firstName: string
  lastName: string
  phone: string
  zipCode: string
}

const InfoPage = (_: RouteComponentProps) => {
  const { upsertProspect, loadingUpsert } = useProspectMutateNoContext()
  const { prospect } = useProspect()
  const { state } = useEstimate()
  const { state: utmState } = useContext(TrackingContext)
  const { fetchUniqueZipCode } = useZipCodes()
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<ProspectInformationForm>({
    mode: 'onTouched',
    defaultValues: {
      email: prospect?.email || '',
      firstName: prospect?.firstName || '',
      lastName: prospect?.lastName || '',
      phone: '',
      zipCode: prospect?.zipCode || '',
    },
  })

  const onSubmit: SubmitHandler<ProspectInformationForm> = async (
    newProspect,
  ) => {
    const validatedAffiliateCode = validateAffiliateCode(
      prospect?.data?.affiliateCode || utmState?.afmc || '',
    )
    const res = await upsertProspect({
      variables: {
        data: {
          ...omit(newProspect, 'affiliateCode'),
          data: {
            ...prospect?.data,
            ...(validatedAffiliateCode && {
              affiliateCode: validatedAffiliateCode.affiliateCode,
            }),
            estimate: state,
            estimateCompletedAt:
              prospect?.data?.estimateCompletedAt || new Date().toISOString(),
            form_contact_source: FormContactSource.ESTIMATE,
            shareASaleTrackingCode: getShareASaleClickId(),
          },
          dataTracking: {
            utmCampaign: utmState.utmccn,
            utmContent: utmState.utmcct,
            utmMedium: utmState.utmcmd,
            utmSource: utmState.utmcsr,
            utmTerm: utmState.utmctr,
            gclid: utmState.gclid,
          },
        },
      },
    })
    const upsertedProspect = res.data?.upsertProspect
    if (upsertedProspect?.hubspotId) {
      fireEvent({
        type: 'identify',
        userId: upsertedProspect.hubspotId,
        email: newProspect.email,
        firstName: newProspect.firstName,
        lastName: newProspect.lastName,
        phone: upsertedProspect.phone ?? '',
        state: upsertedProspect.data.state ?? '',
        zipCode: upsertedProspect.zipCode ?? '',
      })
      fireEvent({
        type: 'prospect_upserted',
        hubspotId: upsertedProspect.hubspotId,
      })
      fireEvent({
        type: 'estimator_completed',
        email: newProspect.email,
        firstName: newProspect.firstName,
        lastName: newProspect.lastName,
        phone: upsertedProspect.phone ?? '',
        state: upsertedProspect.data.state ?? '',
        zipCode: upsertedProspect.zipCode ?? '',
      })
    }
    navigate(`/welcome/${upsertedProspect?.id}/estimate/results`, {
      state: {
        comingFromFlow: true,
      },
    })
  }

  const mainContent = (
    <InfoWrapper>
      <Container textAlign="center">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              {isMobile ? (
                <h4 style={{ marginTop: '12px' }}>Get your instant estimate</h4>
              ) : (
                <h2>Get your instant estimate</h2>
              )}
              <p>See what your new kitchen could cost!</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column computer={7} tablet={10} mobile={16}>
              <StyledForm styled={{ grayInputs: true }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) => {
                      return /[^\w-\s]/.test(value)
                        ? 'No special characters allowed'
                        : true
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="First Name"
                      placeholder="First name"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) => {
                      return /[^\w-\s]/.test(value)
                        ? 'No special characters allowed'
                        : true
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Last Name"
                      placeholder="Last name"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: (value) =>
                      emailIsValid(value) ||
                      'Please enter a valid email address',
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Email"
                      placeholder="Email"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
                <ControlledPhoneInput
                  name="phone"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  label="Phone"
                  placeholder="Phone"
                />
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: {
                      invalid: (value) =>
                        /^(\d{9}|\d{5})$/.test(value) || 'Invalid zip code',
                      fetch: async (value) => {
                        const res = await fetchUniqueZipCode({
                          variables: { where: { zipCode: value } },
                        })
                        return (
                          !!res.data?.zipCode || "We don't deliver here yet"
                        )
                      },
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Form.Input
                      label="Zip Code"
                      placeholder="Zip Code"
                      name={name}
                      value={value}
                      error={
                        !!error?.message && {
                          content: <p className="red small">{error.message}</p>,
                        }
                      }
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                    />
                  )}
                />
              </StyledForm>
              {errors?.zipCode?.type === 'fetch' && (
                <HelpMessage
                  icon="circle-info"
                  title="We don't deliver here yet"
                  message="Sorry, we don’t currently deliver to your zip code yet. You can receive our orders anywhere in the continental US and handle transportation from there. Email hello@formkitchens.com for more information."
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button
                disabled={!isValid || loadingUpsert}
                loading={loadingUpsert}
                onClick={handleSubmit(onSubmit)}
                size={isMobile ? 'fluid' : 'large'}
                kind="solid"
                text="See my results"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </InfoWrapper>
  )

  return (
    <>
      <CssOverride />
      <EstimatorStepLayout
        step="info"
        mainContent={mainContent}
        singleColumnLayout={true}
        hideBottomNav={true}
      />
    </>
  )
}

export default (props: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <InfoPage {...props} />
    </LeadTimeProvider>
  )
}

const InfoWrapper = styled.section`
  padding: 100px 0 50px 0;

  @media ${Breakpoint.onlyMobile} {
    padding: 20px 0;
    overflow-y: auto;
  }
`
