import { motion } from 'framer-motion'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

type StyledAccordionItemProps = {
  open: boolean
}
export const StyledAccordionItem = styled.div<StyledAccordionItemProps>`
  align-items: center;
  border-bottom: ${(props) =>
    !props.open ? `1px dashed ${Colors.gray200}` : ''};
  color: ${Colors.gray800};
  cursor: pointer;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 30px 1.5rem;

  &:focus {
    outline: none;
  }

  @media ${Breakpoint.onlyMobile} {
    /* padding: 0.5rem 1.5rem; */
  }
`

export const StyledAccordionItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
  width: 100%;
  gap: 5px;

  span {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2rem;
    margin-right: 1rem;
  }
`

export const StyledAccordionContent = styled(motion.div)`
  overflow: hidden;
  border-bottom: 1px dashed ${Colors.gray100};
  padding: 0 28px;
  white-space: pre-wrap;
  text-align: left;

  @media ${Breakpoint.downFromComputer} {
    padding: 0;
  }
`

export const StyledAccordionItemTitle = styled.p`
  flex-grow: 1;
  min-width: max-content;
  text-align: left;
  margin: 0;
`

export const ActiveFilter = styled.h4`
  text-align: right;
`
