import React, { useEffect, useState } from 'react'

import { Grid, Image as SuiImage, ImageProps } from 'semantic-ui-react'
import styled from 'styled-components'

import useEstimate from 'context/estimate/use'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { Breakpoint } from 'styles/app/system'
import * as EstimatorData from 'views/welcome/prospect/estimate/data'

import { AccordionItemProps } from '../redo-modal'

const CabinetHeight = ({
  setActiveFilter,
  setRedoState,
}: AccordionItemProps) => {
  const { state } = useEstimate()
  const [active, setActive] = useState(state.cabinetHeight)

  const handleChange = (
    option: (typeof EstimatorData.cabinetHeightOptions)[0],
  ) => {
    setRedoState((prev) => ({
      ...prev,
      cabinetHeight: option.value,
    }))
    setActiveFilter(option.label)
    setActive(option.value)
  }

  useEffect(() => {
    setActiveFilter(
      EstimatorData?.cabinetHeightOptions?.find(
        (o) => o.value === state.cabinetHeight,
      )?.label ?? '',
    )
  }, [])

  return (
    <>
      <ImageContainer>
        {EstimatorData.cabinetHeightOptions.map((o) => (
          <Image
            key={o.value}
            onClick={() => handleChange(o)}
            selected={active === o.value}
            src={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${o.imageUrl}`}
          />
        ))}
      </ImageContainer>
      <Grid padded>
        <Grid.Row stretched>
          {EstimatorData.cabinetHeightOptions.map((o, i) => (
            <Grid.Column
              key={i}
              computer={4}
              tablet={5}
              mobile={8}
              style={{ marginBottom: '24px' }}
            >
              <SelectTextCard
                text={`${i + 1}. ${o.label}`}
                onClick={() => handleChange(o)}
                selected={active === o.value}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </>
  )
}

export default CabinetHeight

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 200px;
  justify-content: center;
  margin: 20px 0;

  @media ${Breakpoint.onlyMobile} {
    height: 150px;
    gap: 10px;
  }
`

const Image: React.FC<ImageProps & { selected: boolean }> = styled(SuiImage)`
  cursor: pointer;
  height: 100%;
  object-fit: contain;
  opacity: ${(props) => (props.selected ? 1 : 0.3)};
  transition: all ease 0.3s;
`
