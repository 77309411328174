import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import useEstimate from 'context/estimate/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import * as EstimatorData from 'views/welcome/prospect/estimate/data'
import { getLayoutDimensions } from 'views/welcome/prospect/estimate/utils'

import { AccordionItemProps } from '../redo-modal'

const Layout = ({ setActiveFilter, setRedoState }: AccordionItemProps) => {
  const { state } = useEstimate()
  const [active, setActive] = useState(state.layout)

  const handleChange = (option: (typeof EstimatorData.layoutOptions)[0]) => {
    const { max, min } = getLayoutDimensions(option.value)
    setRedoState((prev) => ({
      ...prev,
      layout: option.value,
      linearMillimeters: Math.floor((min + max) / 2), // Reset dimensions to match layout dimension range
    }))
    setActiveFilter(option.label)
    setActive(option.value)
  }

  useEffect(() => {
    setActiveFilter(
      EstimatorData?.layoutOptions?.find((o) => o.value === state.layout)
        ?.label ?? '',
    )
  }, [])

  return (
    <Grid padded>
      <Grid.Row stretched>
        {EstimatorData.layoutOptions.map((o) => (
          <Grid.Column
            key={o.label}
            widescreen={3}
            largeScreen={3}
            computer={4}
            tablet={5}
            mobile={8}
            style={{ marginBottom: '24px' }}
          >
            <SelectImageCard
              onClick={() => handleChange(o)}
              image={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${o.imageUrl}`}
              text={o.label}
              selected={o.value === active}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default Layout
