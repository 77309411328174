import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  StyledAccordionItem,
  StyledAccordionItemActions,
  StyledAccordionContent,
  StyledAccordionItemTitle,
  ActiveFilter,
} from './styles'

export type AcordionItemChildrenProps = {
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>
}

type AccordionItemProps = {
  children: (props: AcordionItemChildrenProps) => React.ReactNode
  open: boolean
  setActiveItem: () => void
  title: string
}

const AccordionItem = ({
  children,
  open,
  setActiveItem,
  title,
}: AccordionItemProps) => {
  const [activeFilter, setActiveFilter] = useState('')

  return (
    <>
      <StyledAccordionItem open={open} onClick={setActiveItem}>
        <StyledAccordionItemTitle>{title}</StyledAccordionItemTitle>

        <StyledAccordionItemActions>
          {!open && activeFilter ? (
            <ActiveFilter className="no-margin">{activeFilter}</ActiveFilter>
          ) : null}

          <FontAwesomeIcon icon={['fal', !open ? 'plus' : 'minus']} />
        </StyledAccordionItemActions>
      </StyledAccordionItem>
      <StyledAccordionContent
        variants={{
          closed: {
            height: 0,
            opacity: 0,
            visibility: 'hidden',
          },
          open: {
            height: 'auto',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        initial="closed"
        animate={open ? 'open' : 'closed'}
        transition={{
          damping: 10,
        }}
      >
        {children({ setActiveFilter })}
      </StyledAccordionContent>
    </>
  )
}

type AccordionProps = {
  items: {
    title: string
    content: (props: AcordionItemChildrenProps) => React.ReactNode
  }[]
  style?: React.CSSProperties
}

const Accordion = ({ items, style }: AccordionProps) => {
  const [activeItem, setActiveItem] = useState('')

  return (
    <div style={style}>
      {items.map((item) => (
        <AccordionItem
          key={item.title}
          children={item.content}
          open={activeItem === getIdFromTitle(item.title)}
          setActiveItem={() =>
            setActiveItem(
              activeItem === getIdFromTitle(item.title)
                ? ''
                : getIdFromTitle(item.title),
            )
          }
          title={item.title}
        />
      ))}
    </div>
  )
}

const getIdFromTitle = (title: string) =>
  title.trim().toLowerCase().replaceAll(' ', '_')

export default Accordion
