import React, { useEffect, useState } from 'react'

import { RouteComponentProps, useLocation } from '@reach/router'

import Loader from 'components/shared/loader'

const Welcome = (
  props: RouteComponentProps & { children: React.ReactNode },
) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { pathname } = useLocation()
  const isEstimatorResults = pathname.includes('/estimate/results')

  // add loading to let semantic styles load, to prevent page flash
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1000)
  }, [])

  if (!isLoaded) {
    // just return null on estimator results, due to loader sizing
    if (isEstimatorResults) return null
    return <Loader />
  }

  return <>{props.children}</>
}

export default Welcome
