import React, { useEffect, useState } from 'react'

import HelpMessage from 'components/shared/help-message'
import useEstimate from 'context/estimate/use'

const PriceWarning = () => {
  const { upgrades } = useEstimate()
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    const price = upgrades?.$.price / 100

    if (price < 15_000) {
      setShowWarning(true)
    }

    if (price >= 15_000) {
      setShowWarning(false)
    }
  }, [upgrades])

  return showWarning ? (
    <HelpMessage message="Orders below $15,000 will incur a $1,500 processing and shipping fee." />
  ) : null
}

export default PriceWarning
