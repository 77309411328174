import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import DimensionsSlider from 'components/welcome/estimate/dimensions-slider'
import useEstimate from 'context/estimate/use'
import { formatDimensionFeetAndInches } from 'planner/utils/measurement-utils'
import { TemplateParameters } from 'types/volume'
import { getLayoutDimensions } from 'views/welcome/prospect/estimate/utils'

import { AccordionItemProps } from '../redo-modal'

interface Props extends AccordionItemProps {
  redoState: TemplateParameters
}

const Dimensions = ({ setActiveFilter, setRedoState, redoState }: Props) => {
  const { state } = useEstimate()
  const [value, setValue] = useState(state.linearMillimeters)
  const layoutDimensions = getLayoutDimensions(redoState.layout)
  const prevLayout = useRef(redoState.layout)

  const handleChange = (newValue: number) => {
    setRedoState((prev) => ({
      ...prev,
      linearMillimeters: newValue,
    }))
    setActiveFilter(formatDimensionFeetAndInches(newValue))
    setValue(newValue)
  }

  useEffect(() => {
    setActiveFilter(formatDimensionFeetAndInches(value))
  }, [])

  useEffect(() => {
    // Update active filter and slider value when layout changes
    if (prevLayout.current !== redoState.layout) {
      const { max, min } = getLayoutDimensions(redoState.layout)
      const dimension = Math.floor((min + max) / 2)
      setActiveFilter(formatDimensionFeetAndInches(dimension))
      setValue(dimension)
      prevLayout.current = redoState.layout
    }
  }, [redoState.layout])

  return (
    <Container>
      <DimensionsSlider
        value={value}
        onChange={handleChange}
        layoutDimensions={layoutDimensions}
      />
    </Container>
  )
}

export default Dimensions

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  justify-content: center;
  margin-bottom: 30px;
`
