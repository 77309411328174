import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import { parseFiles as parseProspectFiles } from 'context/prospect/utils'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import NoUploadPlaceholder from 'design-system/components/guide/no-uploads-placeholder'
import { Prospect } from 'types/prospect'

const Uploads = ({ prospect }: { prospect: Prospect | null }) => {
  const { prospectPhotos: photos } = parseProspectFiles(prospect)
  const prospectPhotos = photos?.map(
    (p) => `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(p.key)}`,
  )

  return (
    <>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <h3 className="gray">Your uploads</h3>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          {prospectPhotos.length ? (
            <ImageCarousel images={prospectPhotos} />
          ) : (
            <NoUploadPlaceholder prospectId={prospect?.id ?? ''} />
          )}
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default Uploads
