import React from 'react'

import { RouteComponentProps } from '@reach/router'

import useProspect from 'context/prospect/use'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'

import Budget from './sections/budget'
import DesignDeposit from './sections/design-deposit'
import HowWeWork from './sections/how-we-work'
import NextSteps from './sections/how-we-work/next-steps'
import WhatPeopleSay from './sections/how-we-work/what-people-say'
import LearnMore from './sections/learn-more'
import Timeline from './sections/timeline'
import WhatWeOffer from './sections/what-we-offer'

type FreeConsultationProps = RouteComponentProps<{ prospect_id?: string }>

const navItems: NavigationProps['items'] = [
  {
    title: 'Learn more about your project',
    icon: ['fal', 'home'],
    linkTo: 'learn-more',
  },
  {
    title: 'More about FORM',
    icon: ['fal', 'hexagon'],
    items: [
      { title: 'What we offer', linkTo: 'what-we-offer' },
      { title: 'Budget', linkTo: 'budget' },
      { title: 'Timeline', linkTo: 'timeline' },
    ],
  },
  {
    title: 'Next Steps',
    icon: ['fal', 'clipboard-check'],
    items: [
      { title: 'How we work', linkTo: 'how-we-work' },
      { title: 'Next steps', linkTo: 'next-steps' },
      { title: 'What are people saying?', linkTo: 'people-say' },
      {
        title: '$299 Design Deposit to move forward',
        linkTo: 'design-deposit',
      },
    ],
  },
]

const FreeConsultation = (_props: FreeConsultationProps) => {
  const { prospect } = useProspect()

  return (
    <GuideLayout
      coverProps={{
        src: '/images/interactive-guides/free-consultation/cover.webp',
        subTitle: `${prospect?.firstName} ${prospect?.lastName}`,
        title: 'Free Consultation',
      }}
      introProps={{
        agenda: [
          {
            timer: '25 mins',
            text: 'Learn more about your project and share how we work to see if we could be a good fit.',
          },
        ],
      }}
      guideId="free-consultation-guide"
      navItems={navItems}
      withNotes={false}
    >
      <LearnMore />
      <WhatWeOffer />
      <Budget />
      <Timeline />
      <HowWeWork />
      <NextSteps />
      <WhatPeopleSay />
      <DesignDeposit />
    </GuideLayout>
  )
}

export default FreeConsultation
