import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import FormHeader from 'components/shared/header-form'
import HelpMessage from 'components/shared/help-message'
import DesignersHeader from 'components/welcome/prospect/designers-header'
import LeadTimeProvider, { useLeadTime } from 'context/lead-times/provider'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import { useZipCodes } from 'context/zip-codes/provider'
import Button from 'design-system/components/button'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Drawer } from 'design-system/components/drawer/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint } from 'styles/app/system'
import { resourcesUrls } from 'views/utils'
import UpdateZipCodeModal from 'views/welcome/prospect/onboarding-homeowner/dashboard/update-zipcode-modal'

const Dashboard = () => {
  const [enableUpdateZipCodePack, setEnableUpdateZipCodePack] = useState(false)
  const {
    designer,
    hasProject,
    isTradeProspect,
    prospect,
    prospectUrlComponent,
  } = useProspect()

  const { fetchUniqueZipCode, loading, currentZipCode } = useZipCodes()
  const { leadTime } = useLeadTime()

  useEffect(() => {
    if (isTradeProspect) {
      navigate(`/welcome/${prospect?.id}/trade`)
      return
    }

    if (hasProject) {
      navigate(
        `/login-user/?email=${encodeURIComponent(prospect?.email ?? '')}`,
      )
      return
    }

    fetchUniqueZipCode({
      variables: { where: { zipCode: prospect?.zipCode || 'miss' } },
    })
  }, [prospect, designer])

  if (!prospect || !prospect?.id) {
    navigate('/welcome/new/project-info/get-started/')
  }

  const banner = currentZipCode
    ? {
        message: (
          <p>
            Orders placed today for {currentZipCode.stateName} (
            {prospect?.zipCode}), are anticipated to arrive in{' '}
            <b>
              {leadTime?.weeksLow}-{leadTime?.weeksHigh} weeks
            </b>
            .
          </p>
        ),
        button: (
          <Button
            kind="text"
            text="Learn more"
            fontAwesomeIcon="arrow-right"
            onClick={() => navigate(`/welcome/${prospect?.id}/timeline`)}
          />
        ),
      }
    : {
        message: (
          <p>
            Sorry, we don’t currently deliver to your zip code. You can receive
            our orders anywhere in the continental US and handle transportation
            from there. Email hello@formkitchens.com for more information.
          </p>
        ),
        button: (
          <Button
            kind="text"
            text="Update zip code"
            fontAwesomeIcon="arrow-right"
            onClick={() => setEnableUpdateZipCodePack(true)}
          />
        ),
      }

  return (
    <AppLayout header={<FormHeader />}>
      <StyledGrid stackable>
        {/* Add notifications here */}
        <Grid.Row>
          <Grid.Column>
            <DesignersHeader
              title={`Welcome ${prospect?.firstName}, choose how to get started:`}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {!loading && (
              <HelpMessage
                content={
                  <BannerDiv>
                    {banner.message}
                    {banner.button}
                  </BannerDiv>
                }
                fit
                showIcon={false}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectIconCard
              icon="users"
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/locations/`)
              }}
              text="Visit A Studio"
            >
              <p className="gray no-margin">
                See our kitchens in action at one of our Studios in New York,
                San Francisco, or Denver. Book an appointment.
              </p>
            </SelectIconCard>
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectIconCard
              icon="video-camera"
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`/welcome/${prospectUrlComponent}/schedule-call/`)
              }}
              text="Chat Online"
            >
              <p className="gray no-margin">
                Chat with a FORM Studio Manager to ask questions and learn more.
                Book a virtual call.
              </p>
            </SelectIconCard>
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectIconCard
              icon="credit-card"
              onClick={() => {
                window.open(`/welcome/${prospectUrlComponent}/start-project/`)
              }}
              text="Start with a Designer"
            >
              <p className="gray no-margin">
                Purchase a $499 design package to begin working directly with a
                designer. If FORM isn’t a fit after your first call, you’ll get
                a full refund.
              </p>
            </SelectIconCard>
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
      <div style={{ margin: '30px 0' }}>
        <Drawer
          allowOutsideClick
          content={
            <StyledGrid stackable>
              <Grid.Row stretched>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="oven"
                    onClick={() => window.open(resourcesUrls.whyFORM)}
                    title="Why FORM"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="heart"
                    onClick={() => window.open(resourcesUrls.inspirationGuide)}
                    title="Inspiration Guide"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="ballot-check"
                    onClick={() => window.open(resourcesUrls.projectHandbook)}
                    title="Handbook"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="book-heart"
                    onClick={() =>
                      window.open('https://formkitchens.com/case-studies/')
                    }
                    title="Case Studies"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="images"
                    onClick={() =>
                      window.open('https://formkitchens.com/projects/')
                    }
                    title="Our Projects"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="ballot-check"
                    onClick={() =>
                      window.open('https://formkitchens.com/process/')
                    }
                    title="Our Process"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="sparkles"
                    onClick={() => window.open(resourcesUrls.technicalGuide)}
                    title="Technical Guide"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={8} mobile={16}>
                  <SecondaryCard
                    icon="circle-question"
                    onClick={() => window.open('https://formkitchens.com/faq/')}
                    title="FAQs"
                  />
                </Grid.Column>
              </Grid.Row>
            </StyledGrid>
          }
          title="Useful links"
        />
      </div>
      <UpdateZipCodeModal
        open={enableUpdateZipCodePack}
        onClose={() => setEnableUpdateZipCodePack(false)}
      />
    </AppLayout>
  )
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <Dashboard />
    </LeadTimeProvider>
  )
}

const BannerDiv = styled.div`
  button {
    min-width: fit-content;
  }
  @media ${Breakpoint.upFromMobile} {
    display: inline-flex;
    align-items: center;
    column-gap: 32px;
  }
`

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
      .row {
        margin-bottom: 32px !important;
        row-gap: 24px !important;
      }
    }
    .row {
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
