import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { motion, AnimatePresence } from 'framer-motion'
import { useMedia } from 'react-use'

import { LinesBackground } from 'components/app/background'
import { Breakpoint } from 'styles/app/system'

import {
  StyledActionsContainer,
  StyledModalClose,
  StyledModalContent,
  StyledModalOverlay,
  StyledModalRoot,
  ContentContainer,
  ModalHeader,
} from './styles'

const ModalContent = ({
  children,
  ...props
}: DialogPrimitive.DialogContentProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <>
      <StyledModalOverlay asChild forceMount>
        <motion.div
          id="modalBackdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeIn' }}
        >
          <StyledModalContent {...props} asChild forceMount>
            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  transform: 'translateY(10%)',
                },
                visible: {
                  opacity: 1,
                  transform: `translateY(${isMobile ? '0px' : '-50%'})`,
                  transition: {
                    duration: 0.25,
                    ease: 'easeInOut',
                  },
                },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {children}
            </motion.div>
          </StyledModalContent>
        </motion.div>
      </StyledModalOverlay>
    </>
  )
}

export type ModalProps = {
  actions?: React.ReactNode
  children?: React.ReactNode
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
  contentPadding?: number
  handleClose: () => void
  open: boolean
  showClose?: boolean
}

const Modal = ({
  actions,
  children = null,
  closeOnClickOutside = true,
  closeOnEscape = true,
  contentPadding,
  handleClose,
  open,
  showClose = true,
}: ModalProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  // Needs to be state instead of ref to force rerender and apply conditional styles to ModalContet
  const [actionsContainerRef, setActionsContainerRef] =
    useState<HTMLDivElement | null>(null)

  return (
    <StyledModalRoot open={open}>
      <AnimatePresence>
        {open ? (
          <ModalContent
            onEscapeKeyDown={closeOnEscape ? handleClose : undefined}
            onPointerDownOutside={closeOnClickOutside ? handleClose : undefined}
            style={{
              paddingBottom:
                actions && actionsContainerRef
                  ? actionsContainerRef.clientHeight
                  : undefined,
            }}
          >
            <ModalHeader>
              {showClose ? (
                <>
                  <LinesBackground />

                  <DialogPrimitive.Close asChild onClick={handleClose}>
                    <StyledModalClose>
                      {isMobile ? '' : 'Close'}
                      <FontAwesomeIcon
                        className="close"
                        icon={['fal', 'times']}
                        style={{
                          marginLeft: isMobile ? undefined : 10,
                        }}
                      />
                    </StyledModalClose>
                  </DialogPrimitive.Close>
                </>
              ) : null}
            </ModalHeader>

            <ContentContainer padding={contentPadding}>
              {children}
            </ContentContainer>

            {actions ? (
              <StyledActionsContainer ref={setActionsContainerRef}>
                {actions}
              </StyledActionsContainer>
            ) : null}
          </ModalContent>
        ) : null}
      </AnimatePresence>
    </StyledModalRoot>
  )
}

export default Modal
