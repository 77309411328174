import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors, ScrollBarStyle } from 'styles/app/system'

const ProjectDescription = ({
  description,
  prospectId,
}: {
  description?: string
  prospectId: string
}) => {
  return (
    <Grid.Row>
      <Grid.Column>
        <h6>Project Description</h6>
        <TextArea
          readOnly
          rows={5}
          value={description || 'No Description'}
          onClick={() => {
            if (!description) {
              navigate(
                `/welcome/${prospectId}/consultation/upload-photos-standalone`,
              )
            }
          }}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default ProjectDescription

const TextArea = styled.textarea`
  display: block;
  background-color: ${Colors.gray100};
  color: ${Colors.blue900};
  overflow-y: auto;
  width: 100%;
  border: none;
  resize: none;
  padding: 10px 15px;

  ${ScrollBarStyle}
`
