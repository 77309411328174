import React from 'react'

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

type Props = {
  description: string
  icon: FontAwesomeIconProps['icon']
  title: string
}

const WhatsIncludedItem = ({ description, icon, title }: Props) => {
  return (
    <Item>
      <div className="circle-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <h6 className="no-margin">{title}</h6>
      <p>{description}</p>
    </Item>
  )
}

export default WhatsIncludedItem

const Item = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  min-width: 250px;
  text-align: center;

  div.circle-icon {
    align-items: center;
    background-color: #fcf9f7;
    border-radius: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;

    svg {
      color: ${Colors.orange700};
      font-size: 18px;
    }
  }

  @media ${Breakpoint.onlyMobile} {
    flex-basis: 100%;
  }
`
