import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_PROSPECT,
  FindUniqueProspectPayload,
  FindUniqueProspectVariables,
} from 'queries/prospect'

const CalendlyCallback = (props: RouteComponentProps) => {
  const [fetched, setFetched] = useState(false)

  const urlParams = new URLSearchParams(props.location?.search)
  const email = urlParams.get('invitee_email') ?? undefined

  const { data: { prospect } = {} } = useQuery<
    FindUniqueProspectPayload,
    FindUniqueProspectVariables
  >(FIND_UNIQUE_PROSPECT, {
    variables: {
      where: {
        email,
      },
    },
    skip: !email,
    onCompleted: () => {
      setFetched(true)
    },
  })

  useEffect(() => {
    if (fetched) {
      if (prospect) navigate(`/welcome/${prospect.id}`)
      else navigate('/')
    } else if (!email) navigate('/')
  }, [prospect, fetched, email])

  return <Loader />
}

export default CalendlyCallback
