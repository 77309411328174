import React, { useMemo } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import Header from 'components/welcome/header'
import EstimateProvider from 'context/estimate/provider'
import useProspect from 'context/prospect/use'

interface EstimateProps extends RouteComponentProps<{ prospect_id: string }> {
  children: React.ReactNode
}

const Estimate = (props: EstimateProps) => {
  const { location } = props

  const { prospect, prospectUrlComponent } = useProspect()

  const buttonProps = useMemo(() => {
    const _btnProps: { text: string; action: () => Promise<void> } = {
      text: 'Exit',
      action: () => navigate('/'),
    }
    const params = new URLSearchParams(location?.search)

    if (prospect?.id) {
      _btnProps.text = 'Go to dashboard'
      _btnProps.action = () => navigate(`/welcome/${prospectUrlComponent}`)
    }

    if (params.get('backTo') === 'dashboard') {
      _btnProps.text = 'Back'
    }

    if (params.get('backTo') === 'free-consultation') {
      _btnProps.text = 'Back to Free Consultation'
      _btnProps.action = () =>
        navigate(`/welcome/${prospect?.id}/free-consultation`)
    }

    if (params.get('backTo') === 'design-call') {
      _btnProps.text = 'Back to Initial Call'
      _btnProps.action = async () => history.back()
    }

    return _btnProps
  }, [prospect, prospectUrlComponent, location])

  return (
    <EstimateProvider {...props}>
      <Header
        noExit={!!prospect?.id}
        onExit={buttonProps.action}
        onBackText={buttonProps.text}
        onBack={prospect?.id ? buttonProps.action : undefined}
      />
      {props.children}
    </EstimateProvider>
  )
}

export default Estimate
