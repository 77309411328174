import React, { useEffect, useState } from 'react'

import * as CompanyEmailValidator from 'company-email-validator'
import { useMedia } from 'react-use'
import {
  CheckboxProps,
  Container,
  DropdownProps,
  Form,
  Grid,
  Input,
  InputProps,
  Label,
} from 'semantic-ui-react'
import styled from 'styled-components'

import useCompanyMutateNoContext from 'context/company/use-mutate-no-context'
import useCompanyUniqueLazy from 'context/company/use-unique-lazy'
import { StyledForm } from 'styles/admin/main'
import { Breakpoint, Colors } from 'styles/app/system'
import { Company, IndustryType } from 'types/company'
import { getDropdownOptionsFromEnum, validateEINNumber } from 'views/utils'

import { StepViewProps } from '../steps'

type companyProps = { initCompany?: Company | null } & StepViewProps

const CompanyInformationView = ({
  company: initCompany,
  setCompany,
  setLoading,
  setNextDisabled,
  setOnNext,
}: companyProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { createCompany, updateCompany } = useCompanyMutateNoContext()
  const { fetchCompany } = useCompanyUniqueLazy()

  const initialState: Partial<Company> = {
    name: initCompany?.name ?? '',
    domain: initCompany?.domain ?? '',
    data: {
      industry: initCompany?.data?.industry ?? undefined,
      instagram: initCompany?.data?.instagram ?? '',
      otherIndustry: initCompany?.data?.otherIndustry ?? '',
      size: initCompany?.data?.size ?? '',
      website: initCompany?.data?.website ?? '',
    },
  }
  const [state, setState] = useState<Partial<Company>>(initialState)
  const [inputError, setInputError] = useState<boolean>(false)
  const [domainError, setDomainError] = useState<boolean>(false)

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value, name }: { value: string; name: string },
  ) => {
    setState({ ...state, [name]: value })
  }

  const onChangeDataInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value, name }: { value: string; name: string },
  ) => {
    if (name === 'EINNumber') validEIN(value)
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    })
  }

  const validEIN = (value: string) => {
    if (value && !validateEINNumber(value)) setInputError(true)
    else {
      setInputError(false)
    }
  }

  useEffect(() => {
    if (
      state.name &&
      state?.data?.size &&
      state?.data?.industry &&
      !domainError &&
      !inputError
    )
      setNextDisabled(false)
    else setNextDisabled(true)

    const onSubmit = async () => {
      setLoading(true)
      const identifier = state.domain || state.name || ''
      const { company: oldCompany } = await fetchCompany({
        variables: { where: { identifier } },
      })

      //conditions to update company
      let updateCompanyId
      if (oldCompany && !(oldCompany?.users?.length > 0))
        updateCompanyId = oldCompany.id
      else if (initCompany && !oldCompany) updateCompanyId = initCompany.id

      if (updateCompanyId) {
        const companyUpdated = await updateCompany({
          variables: {
            data: {
              name: state.name,
              ...(state.domain ? { domain: state.domain } : null),
              identifier,
              data: {
                ...state.data,
              },
            },
            where: {
              id: updateCompanyId,
            },
          },
        })
        setCompany(companyUpdated?.data?.updateOneCompany)
      } else if (!oldCompany && !initCompany) {
        const { data } = await createCompany({
          variables: {
            data: {
              name: state.name ?? '',
              ...(state.domain ? { domain: state.domain } : null),
              identifier,
              data: {
                ...state?.data,
              },
            },
          },
        })
        setCompany(data?.createOneCompany)
      } else {
        setCompany(undefined)
      }
      setLoading(false)
    }

    setOnNext(() => onSubmit)
  }, [state])

  return (
    <>
      <Container>
        <Grid centered>
          <Grid.Row>
            <Grid.Column>
              <h2 style={{ textAlign: 'center' }}>Company Information</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={11} tablet={16}>
              <StyledForm>
                <Form.Group widths="equal">
                  <Form.Input
                    label="Company Name"
                    name="name"
                    onChange={onChangeInput as InputProps['onChange']}
                    placeholder="Company Name"
                    required
                    value={state.name}
                  />
                  <LabeledField>
                    <label>Email Domain</label>
                    <Input
                      name="domain"
                      onBlur={() => {
                        if (
                          state.domain &&
                          !CompanyEmailValidator.isCompanyDomain(state.domain)
                        )
                          setDomainError(true)
                        else {
                          setDomainError(false)
                        }
                      }}
                      onChange={onChangeInput as InputProps['onChange']}
                      label="@"
                      value={state.domain}
                    />
                    {domainError && (
                      <Label pointing prompt>
                        <p className="red small">Please enter a valid domain</p>
                      </Label>
                    )}
                  </LabeledField>
                </Form.Group>
                <Form.Group
                  inline
                  style={{
                    alignItems: 'baseline',
                    padding: isMobile ? 0 : '0 7px',
                    margin: '16px 0',
                  }}
                >
                  <label
                    style={{
                      marginRight: isMobile ? 0 : '30px',
                      width: isMobile ? '100%' : 'auto',
                    }}
                  >
                    <p
                      className="gray"
                      style={{ fontSize: '15px', fontWeight: '300' }}
                    >
                      Company Size <span className="red">*</span>
                    </p>
                  </label>
                  <Form.Radio
                    checked={state?.data?.size === '1-10'}
                    label="1-10"
                    name="size"
                    onChange={onChangeDataInput as CheckboxProps['onChange']}
                    value="1-10"
                  />
                  <Form.Radio
                    checked={state?.data?.size === '10-30'}
                    label="10-30"
                    name="size"
                    onChange={onChangeDataInput as CheckboxProps['onChange']}
                    value="10-30"
                  />
                  <Form.Radio
                    checked={state?.data?.size === '30-70'}
                    label="30-70"
                    name="size"
                    onChange={onChangeDataInput as CheckboxProps['onChange']}
                    value="30-70"
                  />
                  <Form.Radio
                    checked={state?.data?.size === '70+'}
                    label="70+"
                    name="size"
                    onChange={onChangeDataInput as CheckboxProps['onChange']}
                    value="70+"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Dropdown
                    label="Industry"
                    name="industry"
                    onChange={onChangeDataInput as DropdownProps['onChange']}
                    options={getDropdownOptionsFromEnum(IndustryType, true)}
                    required
                    selection
                    value={state?.data?.industry}
                  />
                  <Form.Input
                    disabled={state?.data?.industry !== IndustryType.OTHER}
                    label="Other Industry"
                    name="otherIndustry"
                    onChange={onChangeDataInput as InputProps['onChange']}
                    placeholder="Other Industry"
                    value={state?.data?.otherIndustry}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <LabeledField>
                    <label>Company Website</label>
                    <Input
                      name="website"
                      onChange={onChangeDataInput as InputProps['onChange']}
                      label="https://"
                      placeholder="mysite.com"
                      value={state?.data?.website}
                    />
                  </LabeledField>
                  <LabeledField>
                    <label>Instagram handle</label>
                    <Input
                      name="instagram"
                      onChange={onChangeDataInput as InputProps['onChange']}
                      label="@"
                      value={state?.data?.instagram}
                    />
                  </LabeledField>
                </Form.Group>
              </StyledForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default CompanyInformationView

const LabeledField = styled(Form.Field)`
  .labeled > .label {
    background: ${Colors.gray100};
    border: 1px solid ${Colors.gray100};
    border-radius: 0;
    padding: 14px !important;

    font-family: 'GT America';
    font-size: 16px !important;
    font-weight: 100;
    color: ${Colors.gray600};
  }
`
