import React, { useState } from 'react'

import styled from 'styled-components'

import EstimatePlanner from 'components/shared/estimate/estimate-planner'
import EstimateSummary from 'components/shared/estimate/estimate-summary'
import useEstimate from 'context/estimate/use'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import Button from 'design-system/components/button'
import { copyToClipboard } from 'views/utils'

import RedoModal from './redo-modal'
import WhatsIncludedModal from './whats-included-modal'

const WhatsIncluded = () => {
  const {
    sceneRelationshipGraph,
    sceneState,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
  } = useEstimate()

  const [redoModalOpened, setRedoModalOpened] = useState(false)
  const [whatsIncludedOpened, setWhatsIncludedOpened] = useState(false)

  const handleCopyURL = async () => {
    try {
      await copyToClipboard(location.href)
      notifySuccess('URL Copied to Clipboard')
    } catch (error) {
      notifyError('Unable to copy to clipboard. Please, try again.')
    }
  }

  const openRedoModal = () => {
    setRedoModalOpened(true)
  }

  const closeRedoModal = () => {
    setRedoModalOpened(false)
  }

  const openWhatsIncludedModal = () => {
    setWhatsIncludedOpened(true)
  }

  const closeWhatsIncludedModal = () => {
    setWhatsIncludedOpened(false)
  }

  return (
    <>
      <Container>
        <Button
          kind="ghost"
          fontAwesomeIcon="circle-plus"
          onClick={openWhatsIncludedModal}
          text="What's shown"
        />
        <EstimatePlanner
          sceneRelationshipGraph={sceneRelationshipGraph}
          sceneState={sceneState}
          viewBoxBase={viewBoxBase}
          viewBoxPanX={viewBoxPanX}
          viewBoxPanY={viewBoxPanY}
        />
        <Button
          kind="solid"
          color="blue"
          fontAwesomeIcon="edit"
          text="Update Your Estimate"
          onClick={openRedoModal}
        />
        <EstimateSummary />
        <Button
          color="gray"
          fontAwesomeIcon="link"
          kind="ghost"
          onClick={handleCopyURL}
          text="Copy estimate link"
        />
      </Container>

      <RedoModal open={redoModalOpened} handleClose={closeRedoModal} />
      <WhatsIncludedModal
        open={whatsIncludedOpened}
        handleClose={closeWhatsIncludedModal}
      />
    </>
  )
}

export default WhatsIncluded

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;
`
