import React, { useEffect, useState } from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import useEstimate from 'context/estimate/use'
import useProspect from 'context/prospect/use'
import { Modal } from 'design-system/components/modal'
import { WhatsIncluded } from 'planner/utils/volume-utils'
import { Breakpoint } from 'styles/app/system'
import { TemplateUpgrades } from 'types/volume'
import { numberFormatter } from 'views/welcome/prospect/estimate/utils'

import PriceCard from './price-card'
import PriceWarning from './price-warning'
import StartingFromCard from './starting-from-card'

const staticPriceDetails = [
  {
    title: 'Essentials',
    key: '$',
    description:
      'Prioritize affordability while maintaining a high-end look and quality feel',
    subtitle:
      'This specification level is for customers who prioritize affordability while still wanting a high-end look and quality feel.',
    modalDescription:
      'As standard, Essentials includes award-winning hardware and soft close hinges as well as popular features like our integrated trash units and sleek cutlery trays.',
    features: [
      'Essential 1.webp',
      'Essential 2.webp',
      'Essential 3.webp',
      'Essential 4.webp',
    ],
  },
  {
    title: 'Premium',
    key: '$$',
    description: 'Balancing enhanced functionality with affordability.',
    mostPopular: true,
    subtitle:
      'As our most popular specification level, Premium strikes a balance between maximizing functionality and affordability.',
    modalDescription:
      'We incorporate more features that help you get the most out of your space, such as internal drawers, pull-outs, lifting units, lighting and unique drawer organizers.',
    features: [
      'Premium 1.webp',
      'Premium 2.webp',
      'Premium 3.webp',
      'Premium 4.webp',
    ],
  },
  {
    title: 'Luxury',
    key: '$$$',
    description:
      'Prioritizing our most luxurious finishes, advanced features and functionality.',
    subtitle:
      'Customers who opt for our highest specification can expect the best of what we have to offer without the showroom mark-up.',
    modalDescription:
      'Our Luxury tier incorporates stand-out features like specialized corner units, larder pull-outs, internal lighting, and elegant wooden drawer inserts.',
    features: [
      'Luxury 1.webp',
      'Luxury 2.webp',
      'Luxury 3.webp',
      'Luxury 4.webp',
    ],
  },
]

const getPriceDetailsFromUpgrades = (
  upgrades: Record<TemplateUpgrades, WhatsIncluded> | undefined,
) => {
  return Object.keys(upgrades || {})?.map((u) => {
    const { price } = upgrades ? upgrades[u as TemplateUpgrades] : { price: 0 }
    const option = staticPriceDetails.find((s) => s.key === u)

    const priceInDollars = price / 100
    const roundedToNearestHundred = Math.round(priceInDollars / 100) * 100

    return {
      ...option,
      price: numberFormatter.format(roundedToNearestHundred), // Price comes in cents, so we need to show it in full dollars
    }
  })
}

type PriceDetailsModal = {
  description: string
  features: string[]
  open: boolean
  subtitle: string
  title: string
}

type PriceDetails = {
  title: string
  description: string
  excerpt: string
  price: string
  key: string
}

const PriceDetails = () => {
  const { hasProject } = useProspect()
  const { upgrades, updateUpgrades } = useEstimate()
  const isMobile = useMedia(Breakpoint.onlyMobile)

  const [priceDetailsModal, setPriceDetailsModal] = useState<PriceDetailsModal>(
    {
      description: '',
      features: [],
      open: false,
      subtitle: '',
      title: '',
    },
  )

  const openPriceDetailsModal = ({
    description,
    features,
    subtitle,
    title,
  }: Pick<
    PriceDetailsModal,
    'description' | 'title' | 'features' | 'subtitle'
  >) => {
    setPriceDetailsModal({
      description,
      features,
      open: true,
      subtitle,
      title,
    })
  }

  const closePriceDetailsModal = () => {
    setPriceDetailsModal((prev) => ({ ...prev, open: false }))
  }

  useEffect(() => {
    updateUpgrades()
  }, [])

  const priceDetails = getPriceDetailsFromUpgrades(upgrades)
  const startingFrom = priceDetails[0]

  return (
    <PriceDetailsContainer>
      {!isMobile ? <PriceWarning /> : null}
      {hasProject ? (
        priceDetails.map((d: any) => (
          <PriceCard
            {...d}
            onClick={() =>
              openPriceDetailsModal({
                description: d.modalDescription,
                features: d.features,
                subtitle: d.subtitle,
                title: d.title,
              })
            }
          />
        ))
      ) : (
        <StartingFromCard price={startingFrom?.price} />
      )}
      <p className="light-gray note">
        <i>
          *Pricing includes cabinetry only. Final price may differ depending on
          when you kickoff your project, your cabinetry, finishes and feature
          selections.
        </i>
      </p>

      <Modal
        open={priceDetailsModal.open}
        onClose={closePriceDetailsModal}
        title={priceDetailsModal.title}
        size="large"
        zIndex={11}
      >
        <h6>{priceDetailsModal.subtitle}</h6>
        <p className="gray">{priceDetailsModal.description}</p>
        <Divider hidden />
        <Grid padded stackable>
          <Grid.Row stretched>
            {priceDetailsModal.features.map((image, key) => (
              <Grid.Column key={key} computer={4} tablet={5} mobile={8}>
                <Image
                  src={`${process.env.GATSBY_CDN_ROOT}/images/estimate/result/price-details/${image}`}
                  style={{
                    objectFit: 'cover',
                    maxHeight: '190px',
                  }}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Modal>
      {isMobile ? <PriceWarning /> : null}
    </PriceDetailsContainer>
  )
}

export default PriceDetails

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;

  p.note {
    margin: 0 auto;
    max-width: 555px;
    text-align: center;
  }
`
