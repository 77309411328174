import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'

import { StepperProps } from 'design-system/components/stepper'
import { Company } from 'types/company'

import CompanyInformationView from './views/company-information'
import TradeProfileView from './views/trade-profile'

export type ProspectFlowRouteProps = RouteComponentProps<{
  '*': string
}>

export type StepViewProps = ProspectFlowRouteProps & {
  company: Company | undefined
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setOnNext: React.Dispatch<React.SetStateAction<() => Promise<void>>>
}

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<StepViewProps>
}
type StartProspectStepsType = 'companyInformation' | 'tradeProfile'

export const TRADE_STEPS: Record<StartProspectStepsType, NavigationStep> = {
  companyInformation: {
    label: 'Company Information',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'company-information',
    View: CompanyInformationView,
  },
  tradeProfile: {
    label: 'Profile',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'profile',
    View: TradeProfileView,
  },
}

export const TRADE_STEPS_ARRAY = [
  TRADE_STEPS.companyInformation,
  TRADE_STEPS.tradeProfile,
]
