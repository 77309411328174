import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'

const NoUploadPlaceholder = ({ prospectId }: { prospectId: string }) => {
  return (
    <Container>
      <h4>NO UPLOADS</h4>
      <p>Click below to upload to project</p>

      <Button
        onClick={() => {
          navigate(
            `/welcome/${prospectId}/consultation/upload-photos-standalone`,
          )
        }}
        text="Upload"
        color="blue"
        kind="solid"
        size="fluid"
        icon={
          <FontAwesomeIcon
            icon={['fal', 'arrow-right']}
            style={{ marginLeft: 10 }}
          />
        }
      />
    </Container>
  )
}

export default NoUploadPlaceholder

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 200px;
  max-width: 300px;

  h4 {
    color: ${Colors.gray900};
  }

  p {
    color: ${Colors.gray400};
  }
`
