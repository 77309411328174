import React from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'

const ProfileCard = ({
  icon,
  title,
  description,
}: {
  icon: IconName
  title: string
  description: string
}) => {
  return (
    <InputDiv>
      <FontAwesomeIcon icon={['fal', icon]} />
      <div>
        <p className="light-gray no-margin x-small">{title}</p>
        <p>{description}</p>
      </div>
    </InputDiv>
  )
}

export default ProfileCard

const InputDiv = styled.div`
  border: 1px solid ${Colors.blue600};
  border-radius: 4px;
  display: flex;
  padding: 6px 8px;

  svg {
    align-self: center;
    font-size: 16px;
    margin: 10px;
  }
`
