import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import useEstimate from 'context/estimate/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import * as EstimatorData from 'views/welcome/prospect/estimate/data'

import { AccordionItemProps } from '../redo-modal'

const IntegratedLighting = ({
  setActiveFilter,
  setRedoState,
}: AccordionItemProps) => {
  const { state } = useEstimate()
  const [active, setActive] = useState(state.integratedLighting)

  const handleChange = (
    option: (typeof EstimatorData.integratedLightingOptions)[0],
  ) => {
    setRedoState((prev) => ({
      ...prev,
      integratedLighting: option.value,
    }))
    setActiveFilter(option.label)
    setActive(option.value)
  }

  useEffect(() => {
    setActiveFilter(
      EstimatorData?.integratedLightingOptions?.find(
        (o) => o.value === state.integratedLighting,
      )?.label ?? '',
    )
  }, [])

  return (
    <Grid padded>
      <Grid.Row stretched>
        {EstimatorData.integratedLightingOptions.map((o) => (
          <Grid.Column
            key={o.label}
            widescreen={3}
            largeScreen={3}
            computer={4}
            tablet={6}
            mobile={8}
            style={{ marginBottom: '24px' }}
          >
            <SelectImageCard
              onClick={() => handleChange(o)}
              image={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${o.imgUrl}`}
              text={o.label}
              selected={o.value === active}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default IntegratedLighting
