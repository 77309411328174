import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

export const StyledForm = styled(Form)<{ styled?: { grayInputs?: boolean } }>`
  &&&& {
    margin: 0;

    .field {
      margin: 0 0 16px;
    }

    .fields {
      margin: 0 0 16px;

      .field {
        margin: 0;
      }
    }

    input,
    textarea {
      background: ${({ styled }) =>
        styled?.grayInputs ? Colors.gray100 : Colors.white} !important;
      border: none !important;
      border-radius: 1px !important;
      color: ${Colors.black} !important;
      font-family: 'GT America' !important;
      font-size: 16px !important;
      padding: 10px 11px;
    }

    .input {
      align-items: center;

      input {
        background: transparent !important;
        border: 1px solid ${Colors.gray500} !important;
        ::placeholder {
          font-size: 14px !important;
          color: ${Colors.gray300} !important;
        }
      }

      p {
        margin-left: 10px;
      }
    }

    label {
      color: ${Colors.gray500};
      font-family: 'GT America';
      font-size: 14px;
      font-weight: 300;
      line-height: 175%;
      margin: 16px 0 4px;

      :after {
        color: ${Colors.gray700} !important;
      }
    }

    .error {
      label,
      p {
        color: ${Colors.gray600} !important;
      }

      prompt {
        border: 1px solid ${Colors.gray600} !important;
      }

      input {
        background: transparent !important;
        border: 1px solid ${Colors.gray600} !important;
        color: ${Colors.gray800} !important;
      }
    }

    @media ${Breakpoint.downFromComputer} {
      min-height: fit-content;
      padding: 20px 12px;
      padding: 0;

      .fields {
        flex-direction: column;
        margin: 0;
      }
    }
  }
`
