import { useLazyQuery } from '@apollo/client'

import {
  FIND_UNIQUE_COMPANY_UNAUTH,
  FindUniqueCompanyPayload,
  FindUniqueCompanyVariables,
} from 'queries/company'

const useCompanyUniqueLazy = () => {
  const [getCompany, { data: { company } = { company: null }, loading }] =
    useLazyQuery<FindUniqueCompanyPayload, FindUniqueCompanyVariables>(
      FIND_UNIQUE_COMPANY_UNAUTH,
    )

  const fetchCompany = (args: Parameters<typeof getCompany>[0]) =>
    new Promise<FindUniqueCompanyPayload>((resolve, reject) => {
      getCompany({
        ...args,
        onCompleted: (data) => {
          args?.onCompleted?.(data)
          resolve(data)
        },
        onError: (error) => {
          args?.onError?.(error)
          reject(error)
        },
      })
    })

  return {
    fetchCompany,
    company,
    loading,
  }
}

export default useCompanyUniqueLazy
