import React, { useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { NavFooter } from 'components/app/nav-footer'
import FormHeader from 'components/shared/header-form'
import FlowLayout from 'design-system/containers/flow-layout'
import { Company } from 'types/company'

import { TRADE_STEPS_ARRAY as steps, ProspectFlowRouteProps } from './steps'
import { parseQueryString } from '../../onboarding-homeowner/project-info/utils'

const StartProspectFlow = ({
  path: _,
  ...routeProps
}: ProspectFlowRouteProps) => {
  const { '*': subroute, location: routeLocation } = routeProps
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const [company, setCompany] = useState<Company | undefined>(undefined)

  const queryLocation = routeLocation?.search || ''
  const { designer } = parseQueryString(queryLocation)
  const queryParams = designer && queryString.stringify({ designer })

  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    navigate(
      `/welcome/trade-onboarding/flow/company-information${
        queryParams ? `?${queryParams}` : ''
      }`,
    )
  }, [])

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= steps.length - 1

  const handleNext = async () => {
    await onNext?.()
    if (!isLastStep)
      navigate(
        `/welcome/trade-onboarding/flow/${
          steps[currentStepIndex + 1].subroute
        }${queryParams ? `?${queryParams}` : ''}`,
      )
  }

  return (
    <FlowLayout
      header={<FormHeader />}
      main={
        <>
          <Router>
            {steps.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                company={company}
                setCompany={setCompany}
                setLoading={setLoadingButton}
                setOnNext={setOnNext as () => Promise<void>}
                setNextDisabled={setNextDisabled}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          nextText={isLastStep ? 'Sign Up' : 'Continue'}
          onBack={() =>
            navigate?.(
              `/welcome/trade-onboarding/flow/${
                steps[currentStepIndex - 1].subroute
              }${queryParams ? `?${queryParams}` : ''}`,
            )
          }
          onNext={handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps,
      }}
    />
  )
}

export default StartProspectFlow
