import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import EstimatorStepLayout from 'components/welcome/estimate/step-layout'
import useEstimate from 'context/estimate/use'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import Button from 'design-system/components/button'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import PlannerPlan from 'planner/ui/planner-plan'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import { Breakpoint, ScrollBarStyle } from 'styles/app/system'
import { PlannerPlanContainer } from 'styles/welcome/components/estimate'
import * as EstimatorData from 'views/welcome/prospect/estimate/data'

import CantFindLayoutModal from './modals/cant-find-layout-modal'
import { getLayoutDimensions } from '../utils'

const LayoutPage = (props: RouteComponentProps) => {
  const [openModal, setOpenModal] = useState(false)
  const { comingFromDashboard } = (props.location?.state as {
    comingFromDashboard?: boolean
  }) ?? { comingFromDashboard: false }

  const { prospectUrlComponent } = useProspect()
  const isMobile = useMedia(Breakpoint.downFromComputer)

  const {
    dispatch,
    sceneRelationshipGraph,
    sceneState,
    state,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
  } = useEstimate()

  const mainContent = (
    <PlannerPlanContainer>
      <PlannerPlan
        measurementUnit={MeasurementUnit.None}
        moveEnabled={false}
        sceneRelationshipGraph={sceneRelationshipGraph}
        sceneState={sceneState}
        viewBoxBase={viewBoxBase}
        viewBoxPanX={viewBoxPanX}
        viewBoxPanY={viewBoxPanY}
        withBorder={false}
        withGrid={false}
        withPan={false}
        withZoomOnScroll={false}
      />
    </PlannerPlanContainer>
  )

  const LayoutCard = ({ o }: { o: EstimatorData.LayoutOption }) => (
    <SelectImageCard
      key={o.label}
      onClick={() => {
        const { max, min } = getLayoutDimensions(o.value)
        const defaultDimensions = Math.floor((min + max) / 2)
        dispatch({
          type: 'set_dimensions_and_layout',
          layout: o.value,
          linearMillimeters: defaultDimensions,
        })
      }}
      image={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${o.imageUrl}`}
      selected={o.value === state.layout}
      text={o.label}
    />
  )

  const sideContent = (
    <>
      <CantFindLayoutModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      {!isMobile ? (
        <Grid stackable>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <p className="gray" style={{ padding: '16px 0 6px' }}>
                Select your kitchen layout
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            centered
            stretched
            className="row-gap"
            style={{ padding: '14px 20px' }}
          >
            {EstimatorData.layoutOptions.map((o) => (
              <Grid.Column
                key={o.label}
                largeScreen={8}
                computer={16}
                style={{ maxWidth: '230px' }}
              >
                <LayoutCard o={o} />
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button
                kind="text"
                text="Can't find your exact layout"
                color="gray"
                onClick={() => setOpenModal(true)}
                fontAwesomeIcon="question-circle"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <>
          <p className="gray" style={{ textAlign: 'center' }}>
            Select your layout
            <Button
              kind="text"
              text="Help"
              color="gray"
              onClick={() => setOpenModal(true)}
              fontAwesomeIcon="question-circle"
              style={{ marginLeft: '10px' }}
            />
          </p>
          <ScrollableContainer>
            {EstimatorData.layoutOptions.map((o) => (
              <LayoutCard o={o} />
            ))}
          </ScrollableContainer>
        </>
      )}
    </>
  )

  const handleNext = () => {
    window.scroll(0, 0)
    fireEvent({
      type: 'estimator_step_completed',
      stepName: 'LAYOUT',
    })
    navigate(`/welcome/${prospectUrlComponent}/estimate/dimensions`, {
      state: { comingFromDashboard: comingFromDashboard },
    })
  }

  return (
    <EstimatorStepLayout
      handleNext={handleNext}
      mainContent={mainContent}
      sideContent={sideContent}
      step="layout"
      title="Kitchen Layout"
    />
  )
}

export default LayoutPage

const ScrollableContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 20px;

  .select-image-card {
    min-width: 150px;
    padding: 16px 12px;

    p {
      font-size: 14px;
    }
  }

  ${ScrollBarStyle}
`
