import React from 'react'

import ConfirmationModal from 'components/shared/confirmation-modal'

type CantFindLayoutModalProps = {
  handleClose: () => void
  open: boolean
}

const CantFindLayoutModal = ({
  handleClose,
  open,
}: CantFindLayoutModalProps) => {
  return (
    <ConfirmationModal
      confirmText="OK"
      description="Select the layout that is most similar and make sure to add the total linear feet of your space in the next step.<br /> As long as the total space covered is similar, your estimate should be representative."
      firstTitle="Can't find your exact layout?"
      onConfirm={handleClose}
      onCancel={handleClose}
      open={open}
    />
  )
}

export default CantFindLayoutModal
