import React from 'react'

import { navigate } from 'gatsby'

import Button from 'design-system/components/button'
import ThankYou from 'design-system/containers/thank-you'

const ThankYouPage = ({ email }: { email: string }) => {
  return (
    <ThankYou
      imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/payment.svg`}
      mainButton={
        <Button
          fontAwesomeIcon="arrow-right-long"
          kind="solid"
          onClick={() =>
            navigate(`/login-user/?email=${encodeURIComponent(email)}`)
          }
          size="fluid"
          text="Log In"
        />
      }
      subtitle="Your account details have been emailed to you. If you don't see your email soon, please check your Spam folder."
    />
  )
}

export default ThankYouPage
