import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'

const ScheduleCall = (_: RouteComponentProps) => {
  const { hasProject, prospect, startPolling, stopPolling, tradeDesigner } =
    useProspect()

  const handleEventScheduled = () => {
    startPolling(2000)
  }

  useEffect(() => {
    if (hasProject && tradeDesigner) {
      stopPolling()
      navigate(`/welcome/${prospect?.id}/trade/thank-you`)
    }
  }, [prospect, tradeDesigner])

  return (
    <AppLayout>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Meet your trade designer</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={11} tablet={16} textAlign="center">
            <p className="no-margin">
              Scheduling a call helps us gain insight into your business and
              project, ensuring a fast and accurate initial estimate.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <CalendlyInlineScheduler
              calendlyUrl={
                tradeDesigner
                  ? `https://calendly.com/${tradeDesigner.data.calendlyId}/${
                      tradeDesigner.data.calendlyUrlSlugProjectConsultation ||
                      'project-consultation'
                    }`
                  : process.env.GATSBY_CALENDLY_PROJECT_CONSULTATION_URL ?? ''
              }
              user={prospect}
              onEventScheduled={() => {
                handleEventScheduled()
                fireEvent({
                  type: 'identify',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  phone: prospect?.phone,
                  state: prospect?.data.state,
                  zipCode: prospect?.zipCode,
                })
                fireEvent({
                  type: 'schedule_completed',
                  email: prospect?.email,
                  firstName: prospect?.firstName,
                  lastName: prospect?.lastName,
                  meetingType: MeetingTypeProject.PROJECT_CONSULTATION,
                  phone: prospect?.phone,
                  state: prospect?.data.state,
                  zipCode: prospect?.zipCode,
                })
              }}
              prospectEmail={prospect?.email || ''}
              type={MeetingTypeProject.PROJECT_CONSULTATION}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default ScheduleCall
