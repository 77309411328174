import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint } from 'styles/app/system'

const ThankYouTrade = (_: RouteComponentProps) => {
  return (
    <StyledDiv>
      <Image
        centered
        size="medium"
        src={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/messages.svg`}
      />
      <h2>Please verify your email</h2>
      <p className="sub-title" style={{ textAlign: 'center' }}>
        You're almost there! We sent you an email. <br />
        Just click on the link in that email to complete your signup. If you
        don't <br />
        see it, you may need to <b>check your spam</b> folder.
      </p>
    </StyledDiv>
  )
}

export default ThankYouTrade

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  @media ${Breakpoint.onlyMobile} {
    padding: 0 8px;
    text-align: center;
  }
`
