import React from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Form } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import { useZipCodes } from 'context/zip-codes/provider'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/admin/main'

type ProspectInformationForm = {
  zipCode: string
}

const UpdateZipCodeModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const { prospect, refetch } = useProspect()
  const { fetchUniqueZipCode } = useZipCodes()
  const { upsertProspect, loadingUpsert } = useProspectMutateNoContext()
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ProspectInformationForm>({
    mode: 'onTouched',
    defaultValues: {
      zipCode: prospect?.zipCode,
    },
  })

  const onSubmit: SubmitHandler<ProspectInformationForm> = async (
    newProspect,
  ) => {
    await upsertProspect({
      variables: {
        data: {
          email: prospect?.email,
          zipCode: newProspect.zipCode,
        },
      },
    })
    refetch()
    closeAndClean()
  }

  const closeAndClean = () => {
    setValue('zipCode', prospect?.zipCode ?? '')
    onClose()
  }

  return (
    <Modal
      onClose={closeAndClean}
      open={open}
      size="small"
      title="Update zip code"
      saveButton={{
        loading: loadingUpsert,
        onClick: handleSubmit(onSubmit),
      }}
    >
      <StyledForm styled={{ grayInputs: true }}>
        <Controller
          name="zipCode"
          control={control}
          rules={{
            required: 'This field is required',
            validate: {
              invalid: (value) =>
                /^(\d{9}|\d{5})$/.test(value) || 'Invalid zip code',
              fetch: async (value) => {
                const res = await fetchUniqueZipCode({
                  variables: { where: { zipCode: value } },
                  onCompleted: () => {},
                })
                if (res?.data?.zipCode) return true

                return "We don't deliver here yet"
              },
            },
          }}
          render={({
            field: { onBlur, onChange, value, name },
            fieldState: { error },
          }) => (
            <Form.Input
              label="Zip Code"
              placeholder="Zip Code"
              name={name}
              value={value}
              error={
                !!error?.message && {
                  content: <p className="red small">{error.message}</p>,
                }
              }
              onChange={onChange}
              onBlur={onBlur}
              required
            />
          )}
        />
      </StyledForm>
      {errors?.zipCode?.type === 'fetch' && (
        <HelpMessage
          icon="circle-info"
          title="We don't deliver here yet"
          message="Sorry, we don’t currently deliver to your zip code yet. You can receive our orders anywhere in the continental US and handle transportation from there. Email hello@formkitchens.com for more information."
        />
      )}
    </Modal>
  )
}

export default UpdateZipCodeModal
