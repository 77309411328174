import { FC } from 'react'

import {
  Grid,
  GridColumnProps,
  GridProps,
  GridRowProps,
} from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors, ScrollBarStyle } from 'styles/app/system'

export const EstimateGrid: FC<
  { $hideBottomNav?: boolean } & GridProps
> = styled(Grid)`
  &&& {
    padding-top: 61px;
    margin: 0;
    /* Minus height of bottom menu */
    height: ${(props) =>
      props.$hideBottomNav ? '100%' : 'calc(100vh - 65px)'};
  }
`

export const GridContainer: FC<GridRowProps & { overflowY?: boolean }> = styled(
  Grid.Row,
)`
  &&& {
    padding: 0 !important;
    height: 100%;
    overflow: hidden;
    overflow-y: ${(props) => props.overflowY && 'auto !important'};

    ${ScrollBarStyle}
  }
`

export const MainColumn: FC<
  GridColumnProps & { $isSingleColumn?: boolean }
> = styled(Grid.Column)`
  &&& {
    height: 100%;
    overflow: hidden;
    background-color: ${(props) =>
      props.$isSingleColumn ? 'transparent' : Colors.white};
    padding: 0 !important;

    @media ${Breakpoint.downFromComputer} {
      max-height: ${(props) =>
        props.$isSingleColumn ? '100%' : 'calc(100vh - 420px)'};
    }

    ${ScrollBarStyle}
  }
`

export const OptionsColumn: FC<GridColumnProps> = styled(Grid.Column)`
  &&& {
    padding-block: 10px;
    overflow-y: auto;
    height: 100%;

    @media ${Breakpoint.downFromComputer} {
      overflow-y: hidden;
      padding: 10px 0;
    }
  }

  ${ScrollBarStyle}
`

export const MainContainer = styled.div<{
  singleColumnLayout?: boolean
  overflowY?: boolean
}>`
  align-items: ${(props) => (props.singleColumnLayout ? 'unset' : 'center')};
  display: ${(props) => (props.singleColumnLayout ? 'unset' : 'flex')};
  flex-direction: ${(props) => (props.singleColumnLayout ? 'unset' : 'column')};
  height: 100%;
  width: 100%;
  overflow-y: ${(props) => props.overflowY && 'auto !important'};
  ${(props) => (props.overflowY ? ScrollBarStyle : undefined)}
`

export const PlannerPlanContainer = styled.div`
  aspect-ratio: 1 / 1;
  flex-grow: 1;
  max-width: 100%;
  margin-top: -20px;

  @media ${Breakpoint.downFromComputer} {
    margin-top: 0;
  }
`

export const DimensionsOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media ${Breakpoint.downFromComputer} {
    justify-content: flex-start;
  }
`

export const ResultsContainer = styled.div`
  margin: 0 auto;
  background-color: ${Colors.gray50};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  ${ScrollBarStyle}
`
export const ResultsRow: FC<GridRowProps> = styled(Grid.Row)`
  padding: 0 6rem !important;

  @media ${Breakpoint.downFromSmallDesktop} {
    padding: 0 3rem !important;
  }

  @media ${Breakpoint.downFromComputer} {
    padding: 0 1rem !important;
  }
`

export const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media ${Breakpoint.onlyMobile} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding-left: 16px;
    width: 100%;
  }
`
