import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import DimensionsSlider from 'components/welcome/estimate/dimensions-slider'
import EstimatorStepLayout from 'components/welcome/estimate/step-layout'
import useEstimate from 'context/estimate/use'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import fireEvent from 'context/tracking/events'
import Button from 'design-system/components/button'
import PlannerPlan from 'planner/ui/planner-plan'
import { MeasurementUnit } from 'planner/utils/measurement-utils'
import { Breakpoint } from 'styles/app/system'
import {
  DimensionsOptionsContainer,
  PlannerPlanContainer,
} from 'styles/welcome/components/estimate'
import { FormContactSource } from 'types/crm'

import WhatsLinearFeetModal from './modals/whats-linear-feet-modal'
import { getLayoutDimensions } from '../utils'

const DimensionsPage = (props: RouteComponentProps) => {
  const [openModal, setOpenModal] = useState(false)
  const { comingFromDashboard } = (props.location?.state as {
    comingFromDashboard?: boolean
  }) ?? { comingFromDashboard: false }
  const { upsertProspect, loadingUpsert } = useProspectMutateNoContext()

  const { prospectUrlComponent, prospect } = useProspect()
  const {
    dispatch,
    sceneRelationshipGraph,
    sceneState,
    state,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
  } = useEstimate()
  const layoutDimensions = getLayoutDimensions(state.layout)

  const handleNext = async () => {
    window.scroll(0, 0)
    fireEvent({
      type: 'estimator_step_completed',
      stepName: 'DIMENSIONS',
    })

    if (!prospect?.id) {
      navigate(`/welcome/${prospectUrlComponent}/estimate/info`)
      return
    }

    await upsertProspect({
      variables: {
        data: {
          email: prospect.email,
          data: {
            ...prospect.data,
            estimate: state,
            estimateCompletedAt:
              prospect.data.estimateCompletedAt || new Date().toISOString(),
            form_contact_source: FormContactSource.ESTIMATE,
          },
        },
      },
      onCompleted: () => {
        dispatch({
          type: 'set_estimate',
          estimate: state,
        })
        fireEvent({
          type: 'identify',
          userId: prospect.hubspotId,
          email: prospect.email,
          firstName: prospect.firstName,
          lastName: prospect.lastName,
          phone: prospect.phone ?? '',
          state: prospect.data.state ?? '',
          zipCode: prospect.zipCode ?? '',
        })
        fireEvent({
          type: 'estimator_completed',
          email: prospect.email,
          firstName: prospect.firstName,
          lastName: prospect.lastName,
          phone: prospect.phone ?? '',
          state: prospect.data.state ?? '',
          zipCode: prospect.zipCode ?? '',
        })
        navigate(`/welcome/${prospectUrlComponent}/estimate/results`, {
          state: {
            comingFromDashboard: comingFromDashboard,
            comingFromFlow: true,
          },
        })
      },
    })
  }

  const handleDimensionsChange = (value: number) => {
    dispatch({
      type: 'set_dimensions',
      linearMillimeters: value,
    })
  }

  const mainContent = (
    <PlannerPlanContainer>
      <PlannerPlan
        measurementUnit={MeasurementUnit.FeetAndInches}
        moveEnabled={false}
        sceneRelationshipGraph={sceneRelationshipGraph}
        sceneState={sceneState}
        viewBoxBase={viewBoxBase}
        viewBoxPanX={viewBoxPanX}
        viewBoxPanY={viewBoxPanY}
        withBorder={false}
        withGrid={false}
        withPan={false}
        withZoomOnScroll={false}
      />
    </PlannerPlanContainer>
  )

  const sideContent = (
    <DimensionsOptionsContainer>
      <WhatsLinearFeetModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Headline className="gray">
        Enter total linear feet
        <Button
          kind="text"
          text="Help"
          color="gray"
          onClick={() => setOpenModal(true)}
          fontAwesomeIcon="question-circle"
          style={{ marginLeft: '10px' }}
        />
      </Headline>

      <DimensionsSlider
        layoutDimensions={layoutDimensions}
        onChange={handleDimensionsChange}
        value={state.linearMillimeters}
      />
    </DimensionsOptionsContainer>
  )

  return (
    <EstimatorStepLayout
      handleNext={handleNext}
      hideDimmer={true}
      loading={loadingUpsert}
      mainContent={mainContent}
      sideContent={sideContent}
      step="dimensions"
      title="Kitchen dimensions"
    />
  )
}

export default DimensionsPage

const Headline = styled.p`
  text-align: center;

  @media ${Breakpoint.downFromComputer} {
    margin-bottom: 0;
  }
`
