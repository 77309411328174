import React from 'react'

import { Grid } from 'semantic-ui-react'

import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      "When you pay $299 to start your project, you'll receive a login to our website via email.",
    image: `images/interactive-guides/free-consultation/next-step-free-consultation-01.svg`,
  },
  {
    leadText:
      'There are guided <span class="bold">steps</span> to help you <span class="bold">setup your project.</span>',
    image: `images/interactive-guides/free-consultation/next-step-free-consultation-02.svg`,
  },
  {
    leadText:
      'Your designer will need at minimum a <span class="bold">floor plan</span> so they can come prepared to your first call.',
    image: `images/interactive-guides/free-consultation/next-step-free-consultation-03.svg`,
  },
  {
    leadText:
      'Other information is optional and helps get the most out of your time together.',
    image: `images/interactive-guides/free-consultation/next-step-free-consultation-04.svg`,
  },
  {
    leadText:
      'Once you have set up your project, you will be prompted to <span class="bold">schedule the first call</span> with your designer.',
    image: `images/interactive-guides/free-consultation/next-step-free-consultation-05.svg`,
  },
]

const NextSteps = () => {
  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel slideData={nextSteps} />
      </Grid>
    </Section>
  )
}

export default NextSteps
