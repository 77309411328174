import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Slider } from 'react-semantic-ui-range'
import styled from 'styled-components'

import {
  formatDimensionFeetAndInches,
  MILLIMETERS_PER_INCH,
} from 'planner/utils/measurement-utils'
import { Colors } from 'styles/app/system'
import { LayoutDimensions } from 'views/welcome/prospect/estimate/data'

type DimensionsSliderProps = {
  layoutDimensions: LayoutDimensions
  onChange: (value: number) => void
  value: number
}

const DimensionsSlider = ({
  layoutDimensions,
  onChange,
  value,
}: DimensionsSliderProps) => {
  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Label
          layoutDimensions={layoutDimensions}
          onChange={onChange}
          value={value}
        />
        <Slider
          color={Colors.black}
          discrete
          value={value}
          settings={{
            start: value,
            min: layoutDimensions.min,
            max: layoutDimensions.max,
            onChange,
            step: MILLIMETERS_PER_INCH,
          }}
          style={{
            height: '32px',
            track: { top: '15px' },
            trackFill: { top: '15px' },
            thumb: { width: '32px', height: '32px' },
          }}
        />
      </div>
      <LabelsContainer>
        <p>{formatDimensionFeetAndInches(layoutDimensions.min)}</p>

        <p>{formatDimensionFeetAndInches(layoutDimensions.max)}</p>
      </LabelsContainer>
    </Container>
  )
}

export default DimensionsSlider

const Label = ({
  layoutDimensions,
  onChange,
  value,
}: {
  layoutDimensions: LayoutDimensions
  onChange: (value: number) => void
  value: number
}) => {
  const { max, min } = layoutDimensions

  return (
    <LabelContainer>
      <StepButton
        onClick={() => onChange(value - MILLIMETERS_PER_INCH)}
        disabled={Math.floor(value) <= min}
      >
        <FontAwesomeIcon icon={['fal', 'minus']} />
      </StepButton>

      <div>
        <p className="overline gray">Total Linear Feet</p>
        <h4 className="no-margin">{formatDimensionFeetAndInches(value)}</h4>
      </div>

      <StepButton
        onClick={() => onChange(value + MILLIMETERS_PER_INCH)}
        disabled={Math.ceil(value) >= max}
      >
        <FontAwesomeIcon icon={['fal', 'plus']} />
      </StepButton>
    </LabelContainer>
  )
}

const LabelContainer = styled.div`
  align-items: end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
`

const Container = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto;
  max-width: 412px;
  margin-top: 20px;
`

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;

  p {
    margin: 0;
  }
`

const StepButton = styled.button`
  align-items: center;
  background-color: #ffffff;
  border-radius: 500px;
  border: 1px solid ${Colors.gray200};
  box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.03);
  color: ${Colors.orange500};
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  outline: 0;
  padding: 0;
  touch-action: manipulation;
  transition: all ease 0.3s;
  visibility: visible;
  width: 30px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
