import React from 'react'

import { RouteComponentProps } from '@reach/router'

const TradeIndex = (
  props: RouteComponentProps<{ prospect_id: string }> & {
    children?: React.ReactNode
  },
) => {
  return <>{props.children}</>
}

export default TradeIndex
