import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

export const StyledModalOverlay = styled(DialogPrimitive.Overlay)`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  inset: 0;
  isolation: isolate;
  justify-content: center;
  position: fixed;
  z-index: 8001;
`

export const StyledModalContent = styled(DialogPrimitive.Content)`
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  inset: 0;
  position: fixed;
  width: 100%;
  z-index: 50;

  &:focus {
    outline: none;
  }

  @media ${Breakpoint.upFromTablet} {
    height: max-content;
    margin: 0 auto;
    max-height: 850px;
    max-width: 1200px;
    overflow: hidden;
    top: 50%;
    width: 80%;
  }
`

export const StyledModalRoot = styled(DialogPrimitive.Root)``

export const StyledModalClose = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${Colors.gray600};
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 5px;
  z-index: 1;

  &:focus,
  &:hover {
    color: ${Colors.gray800};
  }
`

export const StyledActionsContainer = styled.div`
  align-items: center;
  background-color: ${Colors.gray100};
  border: 1px solid ${Colors.gray200};
  bottom: 0;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  left: 0;
  padding: 1rem 2rem;
  position: absolute;
  right: 0;
  z-index: 1;

  @media ${Breakpoint.onlyMobile} {
    justify-content: space-between;
  }
`

export const ContentContainer = styled.div<{ padding?: number }>`
  overflow-y: auto;
  padding: ${(props) => (props.padding ? `${props.padding}px` : '40px')};

  /* ScrollBar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Colors.gray50};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray400};
    border-radius: 1px;
    width: 110px;
    :hover {
      background-color: ${Colors.gray400};
    }
  }

  @media ${Breakpoint.onlyMobile} {
    padding: 20px;
  }
`

export const ModalHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.gray100};
  display: flex;
  height: 60px !important;
  isolation: isolate;
  justify-content: flex-end;
  left: 0;
  min-height: 60px;
  padding: 0 20px;
  position: absolute;
  position: relative;
  top: 0;
  width: 100%;
`
