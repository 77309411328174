import React, { useState } from 'react'

import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'

import useProspect from 'context/prospect/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import DataBlock from 'design-system/components/guide/data-block'
import ExpandImage from 'design-system/components/guide/expand-image'
import TaskCheck from 'design-system/components/guide/task-check'
import {
  Spacer,
  UnderlineSubtitle,
  OverflowContainer,
} from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const suggestions = [
  { label: 'What have you done so far?' },
  { label: 'Is anyone helping you with the project?' },
  { label: 'Is this a forever home?' },
]

const QuizResults = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const [styleIndex, setStyleIndex] = useState<number | null>(null)
  const {
    inspirationGenerals,
    inspirationMoodboards,
    motivationAnswer,
    priorityAnswer,
    progressAnswer,
    budgetAnswer,
    timelineAnswer,
  } = useProspect()

  const inspiration = inspirationGenerals?.length
    ? inspirationGenerals
    : inspirationMoodboards

  const inspirationImages = inspiration
    ? inspiration.map(
        ({ data }) =>
          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
            data?.imageUrl ?? '',
          )}`,
      )
    : []

  const budgetAndTimeline = []
  if (budgetAnswer) budgetAndTimeline.push(budgetAnswer)
  if (timelineAnswer) budgetAndTimeline.push(timelineAnswer)

  return (
    <>
      {!!inspirationImages?.length && (
        <>
          <Spacer height={150} />
          <Grid.Row>
            <Grid.Column textAlign="center">
              <UnderlineSubtitle>Your favorite styles</UnderlineSubtitle>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {isDownFromComputer ? (
                <OverflowContainer overflowX>
                  {inspirationImages?.map((src, i: number) => (
                    <ExpandImage
                      key={i}
                      idx={i}
                      images={inspirationImages}
                      onClose={() => setStyleIndex(null)}
                      src={src}
                      activeIndex={styleIndex}
                    />
                  ))}
                </OverflowContainer>
              ) : (
                <Grid centered>
                  <Grid.Row columns={4} style={{ rowGap: 20 }}>
                    {inspirationImages?.map((src, i: number) => (
                      <Grid.Column key={i}>
                        <ExpandImage
                          idx={i}
                          images={inspirationImages}
                          onClose={() => setStyleIndex(null)}
                          src={src}
                          activeIndex={styleIndex}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
          <Spacer />
        </>
      )}
      {!!priorityAnswer?.length && (
        <>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <UnderlineSubtitle>Your Priorities</UnderlineSubtitle>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {isDownFromComputer ? (
                <OverflowContainer overflowX>
                  {(priorityAnswer || []).map((p) => (
                    <SelectImageCard
                      key={p.value}
                      image={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/priority/${p.image}`}
                      noHover
                      text={p.value}
                    />
                  ))}
                </OverflowContainer>
              ) : (
                <Grid centered>
                  <Grid.Row columns={4} style={{ rowGap: 20 }} stretched>
                    {(priorityAnswer || []).map((p) => (
                      <Grid.Column key={p.value}>
                        <SelectImageCard
                          image={`${process.env.GATSBY_CDN_ROOT}/images/onboarding/priority/${p.image}`}
                          noHover
                          text={p.value}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
          <Spacer />
        </>
      )}
      <Grid.Row centered className="row-gap">
        {!!motivationAnswer?.length && (
          <Grid.Column computer={4} tablet={8} mobile={16}>
            <DataBlock
              title="Project Drivers"
              icon={['fal', 'bolt-lightning']}
              options={motivationAnswer.map((a) => a.value)}
            />
          </Grid.Column>
        )}
        {!!progressAnswer?.length && (
          <Grid.Column computer={4} tablet={8} mobile={16}>
            <DataBlock
              title="Your Progress"
              icon={['fal', 'spinner']}
              options={progressAnswer.map((a) => a.value)}
            />
          </Grid.Column>
        )}
        {!!budgetAndTimeline?.length && (
          <Grid.Column computer={4} tablet={8} mobile={16}>
            <DataBlock
              title="Budget & Timeline"
              icon={['fal', 'sack-dollar']}
              options={budgetAndTimeline.map((o) => o?.value)}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Spacer height={150} />
      <Grid.Row>
        <Grid.Column>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <UnderlineSubtitle>Suggested questions</UnderlineSubtitle>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={isMobile ? 1 : 3} stretched className="row-gap">
              {suggestions.map(({ label }) => {
                return (
                  <Grid.Column key={label}>
                    <TaskCheck label={label} />
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Spacer height={150} />
    </>
  )
}

export default QuizResults
