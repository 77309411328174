import React from 'react'

import ConfirmationModal from 'components/shared/confirmation-modal'

type WhatsLinearFeetModalProps = {
  handleClose: () => void
  open: boolean
}

const WhatsLinearFeetModal = ({
  handleClose,
  open,
}: WhatsLinearFeetModalProps) => {
  return (
    <ConfirmationModal
      confirmText="OK"
      description="For our estimates, total linear feet is the total wall length covered by your cabinets. <br /> To work it out, measure each wall length that has cabinets and add them
    together.<br /> There is no need to capture island or upper cabinet measurements. We add
    those automatically."
      firstTitle="What is total linear feet?"
      onConfirm={handleClose}
      onCancel={handleClose}
      open={open}
    />
  )
}

export default WhatsLinearFeetModal
