import React, { useEffect, useState } from 'react'

import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import Loader from 'components/shared/loader'
import Modal from 'components/welcome/estimate/modal'
import useEstimate from 'context/estimate/use'
import {
  generateWhatsIncludedFromVolumes,
  WhatsIncluded,
} from 'planner/utils/volume-utils'
import { Breakpoint } from 'styles/app/system'
import { includedCabinets } from 'views/welcome/prospect/estimate/data'

import WhatsIncludedItem from './whats-included-item'

type IncludedItem = {
  description: string
  icon: FontAwesomeIconProps['icon']
  title: string
}

type ValidCabinet = {
  count: number
  imgUrl: string
  key: keyof WhatsIncluded
  label: string
}

const includedItems: IncludedItem[] = [
  {
    description:
      'If there is a manufacturing defect, we will replace it without a quibble.',
    icon: ['fal', 'award'],
    title: 'Lifetime manufacturing warranty',
  },
  {
    description:
      'We hope it never happens, but our kitchens are engineered for everything life throws at them. Sink units come with waterproof base panels. Toe kicks and end panels have a protective shoe. Cabinets are sealed and sit on adjustable legs.',
    icon: ['fal', 'droplet-slash'],
    title: 'Flood Protection Package',
  },
  {
    description:
      'We use the same award-winning hinges and drawer sliders as top European brands.',
    icon: ['fal', 'cabinet-filing'],
    title: 'Soft Close Doors & Drawers',
  },
]

type WhatsIncludedModalProps = {
  handleClose: () => void
  open: boolean
}

const WhatsIncludedModal = ({ handleClose, open }: WhatsIncludedModalProps) => {
  const { nobiliaFeatures, volumes } = useEstimate()
  const [isFetching, setIsFetching] = useState(false)
  const [validCabinets, setValidCabinets] = useState<ValidCabinet[]>([])

  useEffect(() => {
    if (!open) return
    const getData = async () => {
      setIsFetching(true)

      try {
        const result = await generateWhatsIncludedFromVolumes(
          volumes,
          nobiliaFeatures,
        )

        const _includedCabinets: ValidCabinet[] = includedCabinets.map((c) => ({
          ...c,
          count: result[c.key],
        }))

        setValidCabinets(_includedCabinets.filter((c) => result[c.key] > 0))
      } catch (error) {
        console.error(error)
        setValidCabinets([])
      }

      setIsFetching(false)
    }

    getData()
  }, [volumes, open])

  return (
    <Modal handleClose={handleClose} open={open}>
      <h2 style={{ textAlign: 'center' }}>What's shown?</h2>
      {isFetching ? (
        <div style={{ minHeight: '250px', padding: '100px 0' }}>
          <Loader flex fullScreen={false} />
        </div>
      ) : (
        <CabinetsContainer>
          {validCabinets.map((c) => {
            return (
              <CabinetImgContainer key={c.imgUrl}>
                <Image
                  centered
                  src={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${c.imgUrl}`}
                />
                <p className="caption no-margin">{c.count}</p>
                <p className="gray">{c.label}</p>
              </CabinetImgContainer>
            )
          })}

          <ItemsContainer>
            {includedItems.map((i) => (
              <WhatsIncludedItem {...i} key={i.title} />
            ))}
          </ItemsContainer>
        </CabinetsContainer>
      )}
    </Modal>
  )
}

export default WhatsIncludedModal

const CabinetsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  margin-top: 35px;
`

const CabinetImgContainer = styled.div`
  margin-bottom: 10px;

  img {
    height: 150px;
    margin-bottom: 16px;
  }

  @media ${Breakpoint.onlyMobile} {
    flex-basis: 45%;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`
