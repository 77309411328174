import React, { useState } from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid, Image } from 'semantic-ui-react'

import ImageCarousel from 'design-system/components/carousel/image-carousel'
import Cover from 'design-system/components/guide/cover'
import ExpandImage from 'design-system/components/guide/expand-image'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const CDN = process.env.GATSBY_CDN_ROOT

const finalKitchens = [
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-01.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-02.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-03.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-04.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-05.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-06.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-07.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-08.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-09.webp`,
  `${CDN}/images/interactive-guides/free-consultation/introducing-form-10.webp`,
]

const assembledCabinets = [
  `${CDN}/images/interactive-guides/free-consultation/cabinets-1.jpg`,
  `${CDN}/images/interactive-guides/free-consultation/cabinets-02.webp`,
  `${CDN}/images/interactive-guides/free-consultation/cabinets-3.jpg`,
  `${CDN}/images/interactive-guides/free-consultation/cabinets-4.jpg`,
]

const visuals = [
  {
    src: `${CDN}/images/interactive-guides/free-consultation/photo-of-space.webp`,
    title: 'Photo of the space',
  },
  {
    src: `${CDN}/images/interactive-guides/free-consultation/other-studio-visual.webp`,
    title: 'Typical visuals provided by other studios',
  },
  {
    src: `${CDN}/images/interactive-guides/free-consultation/form-visual.webp`,
    title: 'FORM photorealistic visuals',
  },
  {
    src: `${CDN}/images/interactive-guides/free-consultation/form-kitchen.webp`,
    title: 'Photo of final kitchen',
  },
]

const WhatWeOffer = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const [visualsIndex, setVisualsIndex] = useState<number | null>(null)

  return (
    <>
      <Cover
        idSection="what-we-offer"
        src="/images/interactive-guides/free-consultation/introducing-form.webp"
        orientation="row"
        title="Introducing FORM"
      />
      <Section>
        <Grid centered>
          <Grid.Row style={{ gap: 20 }}>
            <Grid.Column width={16} textAlign="center">
              <h3 className="gray">
                We supply made-to-order German cabinetry, beautifully designed
                to help you get the most out of your space.
              </h3>
            </Grid.Column>
            <Divider hidden />
            <Grid.Column width={16}>
              <ImageCarousel images={finalKitchens} />
            </Grid.Column>
          </Grid.Row>
          <Spacer height={50} />
          <Grid.Row style={{ gap: 20 }}>
            <Grid.Column width={16} textAlign="center">
              <h3 className="gray">
                Our cabinets come fully assembled with award-winning hardware.
              </h3>
            </Grid.Column>
            <Divider hidden />
            <Grid.Column width={16}>
              {isDownFromComputer ? (
                <ImageCarousel images={assembledCabinets} />
              ) : (
                <Grid centered stackable>
                  <Grid.Row>
                    {assembledCabinets.map((src) => (
                      <Grid.Column key={src} width={4}>
                        <Image
                          src={src}
                          style={{ height: '100%', objectFit: 'contain' }}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column
                      tablet={16}
                      computer={8}
                      verticalAlign="middle"
                    >
                      <h3 className="no-margin">
                        You work 1:1 with an expert kitchen designer via our
                        project dashboard.
                      </h3>
                      <Spacer height={40} />
                      <p className="gray">
                        All our client-facing designers are based in the US.
                        Fewer that 1 in 8 designers who apply meet our
                        standards.
                      </p>
                    </Grid.Column>
                    {isDownFromComputer ? <Spacer height={50} /> : null}
                    <Grid.Column tablet={16} computer={8}>
                      <Image
                        src={`${CDN}/images/interactive-guides/free-consultation/free-consultation-work-with-designer.svg`}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Grid textAlign="center" padded>
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle">
                      <h3 className="no-margin gray">
                        We then bring your design to life with our
                        photorealistic visuals.
                      </h3>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider hidden />
                  <Grid.Row className="row-gap" stretched>
                    {isDownFromComputer ? (
                      <ImageCarousel images={visuals.map((v) => v.src)} />
                    ) : (
                      visuals.map((v, idx) => (
                        <Grid.Column key={v.title} width={8}>
                          <ExpandImage
                            activeIndex={visualsIndex}
                            idx={idx}
                            images={visuals.map((v) => v.src)}
                            onClose={() => setVisualsIndex(null)}
                            src={v.src}
                            title={v.title}
                          />
                        </Grid.Column>
                      ))
                    )}
                  </Grid.Row>
                </Grid>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column
                      tablet={16}
                      computer={8}
                      verticalAlign="middle"
                    >
                      <h3 className="no-margin gray">
                        Our online model allows us to offer prices up to 40%
                        less than other showrooms.
                      </h3>
                    </Grid.Column>
                    {isDownFromComputer && <Spacer height={50} />}
                    <Grid.Column tablet={16} computer={8}>
                      <Image
                        src={`${CDN}/images/interactive-guides/free-consultation/price-comparison.svg`}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Section>
    </>
  )
}

export default WhatWeOffer
