import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import Header from 'components/app/project/header/header-basic'
import FormHeader from 'components/shared/header-form'
import useProspect from 'context/prospect/use'
import FlowLayout from 'design-system/containers/flow-layout'

import { PROSPECT_QUIZ_STEPS_ARRAY, ProspectFlowRouteProps } from './steps'
import { QuizContext } from '..'

const StartProspectFlow = ({
  path: _,
  ...routeProps
}: ProspectFlowRouteProps) => {
  const { '*': subroute, navigate } = routeProps
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const { prospect, prospectUrlComponent } = useProspect()
  const { affiliateCode, allowSelection, navigateWithQS, state, location } =
    useContext(QuizContext)

  const steps = PROSPECT_QUIZ_STEPS_ARRAY

  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    if (affiliateCode) {
      navigateWithQS(
        `/welcome/${prospectUrlComponent}/project-info/${
          allowSelection ? 'get-started' : 'your-details'
        }`,
      )
    } else {
      navigateWithQS(
        `/welcome/${prospectUrlComponent}/project-info/${
          location ? 'your-details' : 'get-started'
        }`,
      )
    }
  }, [])

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= steps.length - 1
  const isLocationVisit = !!state.answers.find(
    (c) => c.value === 'studio-visit',
  )

  const handleNext = async () => {
    await onNext?.()

    if (!isLastStep) {
      const nextRoute = isLocationVisit
        ? steps[currentStepIndex + 1]?.subroute
        : 'your-details'

      navigateWithQS(
        `/welcome/${prospectUrlComponent}/project-info/${nextRoute}/`,
      )
    }
  }

  return (
    <FlowLayout
      header={
        prospect?.id ? (
          <Header onBack={() => navigate?.(`/welcome/${prospect.id}`)} />
        ) : (
          <FormHeader />
        )
      }
      main={
        <>
          <Router>
            {steps.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setLoading={setLoadingButton}
                setOnNext={setOnNext as () => Promise<void>}
                setNextDisabled={setNextDisabled}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep || !!affiliateCode || !!location}
          backDisabled={isFirstStep || !!affiliateCode || !!location}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          onBack={() => {
            const backRoute =
              !isLocationVisit && isLastStep
                ? 'get-started'
                : steps[currentStepIndex - 1]?.subroute

            navigateWithQS(
              `/welcome/${prospectUrlComponent}/project-info/${backRoute}/`,
            )
          }}
          onNext={handleNext}
        />
      }
    />
  )
}

export default StartProspectFlow
