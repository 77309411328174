import React, { useEffect, useState } from 'react'

import { Dimmer, DimmerProps, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'

const SwipeDimmer = ({ children }: { children: React.ReactNode }) => {
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setIsActive(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [])
  return (
    <Dimmer.Dimmable dimmed={isActive}>
      {children}
      <div className="down-computer">
        <StyledDimmer
          active={isActive}
          inverted
          onClick={() => setIsActive(false)}
          onClickOutside={() => setIsActive(false)}
        >
          <StyledLabel color="black" className="caption">
            <div className="swipe">
              <img
                src={`${process.env.GATSBY_CDN_ROOT}/images/app/swipe-hand.gif`}
                alt="Swipe"
              />
            </div>
            <div>Swipe</div>
          </StyledLabel>
        </StyledDimmer>
      </div>
    </Dimmer.Dimmable>
  )
}

export default SwipeDimmer

const StyledLabel = styled(Label)`
  &&&& {
    align-items: center;
    background-color: ${Colors.gray900} !important;
    border-radius: 32px;
    box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row;
    font-size: 16px;
    justify-content: center;
    padding: 8px 24px;
    height: 100%;

    .swipe {
      margin-right: 16px;
      width: 32px;
    }

    img {
      width: 32px;
    }
  }
`

const StyledDimmer: React.FC<DimmerProps> = styled(Dimmer)`
  padding: 0;
`
