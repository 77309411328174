import React from 'react'

import { RouteComponentProps } from '@reach/router'

import ProspectProvider from 'context/prospect/provider'

const ProspectView = (
  props: RouteComponentProps<{ prospect_id: string }> & {
    children?: React.ReactNode
  },
) => {
  return (
    <ProspectProvider prospect_id={props.prospect_id}>
      {props.children}
    </ProspectProvider>
  )
}

export default ProspectView
