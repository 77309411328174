import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import * as CompanyEmailValidator from 'company-email-validator'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'
import {
  DropdownProps,
  Form,
  Grid,
  Input,
  InputProps,
  Label,
} from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import useCompanyMutateNoContext from 'context/company/use-mutate-no-context'
import useCompanyUniqueLazy from 'context/company/use-unique-lazy'
import useProspect from 'context/prospect/use'
import useProspectMutate from 'context/prospect/use-mutate'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { StyledForm } from 'styles/app/components/form'
import { IndustryType, ProfessionalDesignAssociationTypes } from 'types/company'
import { getDropdownOptionsFromEnum, validateEINNumber } from 'views/utils'

const CompanyInformation = (_: RouteComponentProps) => {
  const { prospect } = useProspect()
  const { updateCompany } = useCompanyMutateNoContext()
  const { updateProspectAndRefetch } = useProspectMutate()
  const { fetchCompany } = useCompanyUniqueLazy()
  const initialState = {
    name: prospect?.company?.name || '',
    domain: prospect?.company?.domain || '',
    EINNumber: prospect?.company?.data?.EINNumber || '',
    industry: prospect?.company?.data?.industry || null,
    instagram: prospect?.company?.data?.instagram || '',
    membershipNumber: prospect?.company?.data?.membershipNumber || '',
    professionalDesignAssociation:
      prospect?.company?.data?.professionalDesignAssociation ||
      ('' as ProfessionalDesignAssociationTypes),
    size: prospect?.company?.data?.size || '',
    website: prospect?.company?.data?.website || '',
  }
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState<boolean>(false)
  const [isValid, setValid] = useState<boolean>(false)
  const [inputError, setInputError] = useState<boolean>(false)
  const [domainError, setDomainError] = useState<boolean>(false)
  const [companyExists, setCompanyExists] = useState<boolean>(false)

  useEffect(() => {
    if (CompanyEmailValidator.isCompanyEmail(prospect?.email ?? '')) {
      setState({
        ...state,
        domain: prospect?.email.split('@')[1] ?? '',
      })
    }
  }, [prospect?.email])

  useEffect(() => {
    if (
      state.name &&
      state.size &&
      state.industry &&
      (state.EINNumber || state.membershipNumber)
    )
      setValid(true)
    else setValid(false)
  }, [state])

  const onSave = async () => {
    setLoading(true)
    const identifier = state.domain || state.name
    const { company } = await fetchCompany({
      variables: { where: { identifier } },
    })
    if (!prospect?.company?.id && company) setCompanyExists(true)
    else if (prospect?.company?.id) {
      await updateCompany({
        variables: {
          data: {
            name: state.name,
            ...(state.domain ? { domain: state.domain } : null),
            identifier,
            data: {
              industry: state.industry,
              ...(state.EINNumber ? { EINNumber: state.EINNumber } : null),
              ...(state.instagram ? { instagram: state.instagram } : null),
              ...(state.membershipNumber
                ? { membershipNumber: state.membershipNumber }
                : null),
              ...(state.professionalDesignAssociation
                ? {
                    professionalDesignAssociation:
                      state.professionalDesignAssociation,
                  }
                : null),
              ...(state.size ? { size: state.size } : null),
              ...(state.website ? { website: state.website } : null),
            },
          },
          where: {
            id: prospect.company.id,
          },
        },
      })
      navigate(`/welcome/${prospect.id}/trade`)
    } else {
      await updateProspectAndRefetch({
        variables: {
          data: {
            company: {
              create: {
                name: state.name,
                ...(state.domain ? { domain: state.domain } : null),
                identifier,
                data: {
                  industry: state.industry as IndustryType,
                  ...(state.EINNumber ? { EINNumber: state.EINNumber } : null),
                  ...(state.instagram ? { instagram: state.instagram } : null),
                  ...(state.membershipNumber
                    ? { membershipNumber: state.membershipNumber }
                    : null),
                  ...(state.professionalDesignAssociation
                    ? {
                        professionalDesignAssociation:
                          state.professionalDesignAssociation,
                      }
                    : null),
                  ...(state.size ? { size: state.size } : null),
                  ...(state.website ? { website: state.website } : null),
                },
              },
            },
          },
          where: {
            id: prospect?.id ?? '',
          },
        },
      })
      navigate(`/welcome/${prospect?.id}/trade`)
    }
    setLoading(false)
  }

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value, name }: { value: string; name: string },
  ) => {
    setState({ ...state, [name]: value })
  }

  const formChanged = !isEqual(initialState, state)

  return (
    <AppLayout>
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <h2 style={{ textAlign: 'center' }}>Company Information</h2>
          </Grid.Column>
        </Grid.Row>
        {companyExists ? (
          <Grid.Row>
            <Grid.Column>
              <HelpMessage
                icon="circle-info"
                title="Looks like your company is already working with us"
                message="Please email help@formkitchens.com so we can set you up with a project."
              />
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column computer={9} tablet={16}>
                <StyledForm>
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Company Name"
                      name="name"
                      onChange={onChangeInput as InputProps['onChange']}
                      placeholder="Company Name"
                      required
                      value={state.name}
                    />
                    <Form.Dropdown
                      label="Company Size"
                      name="size"
                      onChange={onChangeInput as DropdownProps['onChange']}
                      options={[
                        {
                          key: '1',
                          text: '1-10',
                          value: '1-10',
                        },
                        {
                          key: '2',
                          text: '10-30',
                          value: '10-30',
                        },
                        {
                          key: '3',
                          text: '30-70',
                          value: '30-70',
                        },
                        {
                          key: '4',
                          text: '70+',
                          value: '70+',
                        },
                      ]}
                      required
                      selection
                      value={state.size}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Dropdown
                      label="Industry"
                      name="industry"
                      onChange={onChangeInput as DropdownProps['onChange']}
                      options={getDropdownOptionsFromEnum(IndustryType, true)}
                      required
                      selection
                      value={state.industry ?? undefined}
                    />
                    <Form.Field>
                      <label>Email Domain</label>
                      <Input
                        name="domain"
                        onBlur={() => {
                          if (
                            state.domain &&
                            !CompanyEmailValidator.isCompanyDomain(state.domain)
                          )
                            setDomainError(true)
                          else {
                            setDomainError(false)
                          }
                        }}
                        onChange={onChangeInput as InputProps['onChange']}
                        label="@"
                        value={state.domain}
                      />
                      {domainError && (
                        <Label pointing prompt>
                          <p className="red small">
                            Please enter a valid domain
                          </p>
                        </Label>
                      )}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Company Website</label>
                      <Input
                        name="website"
                        onChange={onChangeInput as InputProps['onChange']}
                        label="https://"
                        placeholder="mysite.com"
                        value={state.website}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Instagram handle</label>
                      <Input
                        name="instagram"
                        onChange={onChangeInput as InputProps['onChange']}
                        label="@"
                        value={state.instagram}
                      />
                    </Form.Field>
                  </Form.Group>
                </StyledForm>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={9} tablet={16}>
                <p className="caption">
                  Please provide at least one of the following business details
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={9} tablet={16}>
                <StyledForm>
                  <Form.Group widths={2}>
                    <Form.Input
                      error={
                        inputError && (
                          <Label pointing prompt>
                            <p className="red small">
                              Please enter a valid EIN number, e.g. 12-3456789
                            </p>
                          </Label>
                        )
                      }
                      label="EIN Number"
                      name="EINNumber"
                      onBlur={() => {
                        if (
                          state.EINNumber &&
                          !validateEINNumber(state.EINNumber)
                        )
                          setInputError(true)
                        else {
                          setInputError(false)
                        }
                      }}
                      onChange={onChangeInput as InputProps['onChange']}
                      placeholder="XX-XXXXXXX"
                      value={state.EINNumber}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Dropdown
                      label="Professional Design Association"
                      name="professionalDesignAssociation"
                      onChange={onChangeInput as DropdownProps['onChange']}
                      options={getDropdownOptionsFromEnum(
                        ProfessionalDesignAssociationTypes,
                        true,
                      )}
                      selection
                      upward
                      value={state.professionalDesignAssociation}
                    />
                    <Form.Input
                      disabled={!state.professionalDesignAssociation}
                      label="Membership Number"
                      name="membershipNumber"
                      onChange={onChangeInput as InputProps['onChange']}
                      placeholder="Membership Number"
                      value={state.membershipNumber}
                    />
                  </Form.Group>
                </StyledForm>
              </Grid.Column>
            </Grid.Row>
            <SaveToast
              disabled={!isValid}
              loading={loading}
              mainButtonText="Complete"
              message="Are your company details complete?"
              onClick={onSave}
              show={formChanged}
            />
          </>
        )}
      </Grid>
    </AppLayout>
  )
}

export default CompanyInformation
