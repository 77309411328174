import React, { createContext } from 'react'

import { RouteComponentProps, useLocation } from '@reach/router'

import Loader from 'components/shared/loader'
import ResultsLoader from 'components/welcome/estimate/loader'
import useProspect from 'context/prospect/use'
import { NodeRelationshipGraph } from 'planner/compose'
import { NodeBase } from 'planner/objects/node'
import { VolumeBase } from 'planner/objects/volume'
import { WhatsIncluded } from 'planner/utils/volume-utils'
import { NobiliaFeature } from 'types/nobilia-feature'
import { TemplateParameters, TemplateUpgrades } from 'types/volume'

import { EstimateReducerAction } from './reducer'
import useEstimatePlanner from './use-estimate-planner'

interface IEstimateContext {
  dispatch: (value: EstimateReducerAction) => void
  nobiliaFeatures: NobiliaFeature[]
  loadingUpgrades: boolean
  sceneRelationshipGraph: NodeRelationshipGraph
  sceneState: NodeBase[]
  state: TemplateParameters
  updateUpgrades: (templateParameters?: TemplateParameters) => Promise<void>
  upgrades: Record<TemplateUpgrades, WhatsIncluded>
  viewBoxBase: number
  viewBoxPanX: number
  viewBoxPanY: number
  volumes: VolumeBase[]
}

interface EstimateProps extends RouteComponentProps<{ prospect_id: string }> {
  children: React.ReactNode
}

export const EstimateContext = createContext<IEstimateContext>(
  {} as IEstimateContext,
)

const EstimateProvider = (props: EstimateProps) => {
  const { pathname } = useLocation()
  const isEstimatorResults = pathname.includes('/estimate/results')
  const { prospect } = useProspect()
  const { loading, ...estimatePlanner } = useEstimatePlanner(prospect)

  if (loading) {
    if (isEstimatorResults) {
      return <ResultsLoader withText={false} />
    }
    return <Loader />
  }

  return (
    <EstimateContext.Provider value={estimatePlanner as IEstimateContext}>
      {props.children}
    </EstimateContext.Provider>
  )
}

export default EstimateProvider
