import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProspect from 'context/prospect/use'
import { Section, Spacer } from 'design-system/components/guide/utils'

import ProjectDescription from './parts/project-description'
import QuizResults from './parts/quiz-results'
import Uploads from './parts/uploads'
import WhyForm from './parts/why-form'

const LearnMore = () => {
  const { prospect } = useProspect()

  return (
    <Section id="learn-more">
      <Grid centered>
        <Uploads prospect={prospect} />
        <Spacer height={40} />
        <ProjectDescription
          description={prospect?.data.projectDescription}
          prospectId={prospect?.id ?? ''}
        />
        <Spacer height={40} />
        <QuizResults />
        <Spacer height={40} />
        <WhyForm />
        <Spacer height={40} />
      </Grid>
    </Section>
  )
}

export default LearnMore
