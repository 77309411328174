import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import moment from 'moment'
import { Form } from 'semantic-ui-react'

import PaymentCard from 'components/shared/project/payment/card'
import Checkout from 'components/welcome/checkout'
import { meetingTypeDuration, meetingTypeName } from 'context/meeting/mappers'
import { notifyError } from 'context/notifications/trigger'
import useProspect from 'context/prospect/use'
import {
  CREATE_PROJECT,
  CreateProjectPayload,
  CreateProjectVariables,
} from 'queries/project'
import { StyledForm } from 'styles/app/components/form'
import { MeetingTypeProject } from 'types/meeting'
import { PaymentType, PaymentMethodType, PaymentSource } from 'types/payment'
import { ProjectType } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'
import { ProjectUserRoleTrade } from 'types/project-user'
import { UserTypeTrade } from 'types/user'
import { emailIsValid } from 'views/utils'

interface StartProjectTradeCheckoutProps {
  onCompleted?: (email: string) => void
}

const StartProjectTradeCheckout = ({
  onCompleted,
}: StartProjectTradeCheckoutProps) => {
  const { prospect, designer, tradeDesigners } = useProspect()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    email: prospect?.email.trim().toLocaleLowerCase() || '',
    emailInputError: false,
  })

  const payment = {
    amount: 9900,
    description: 'Design Deposit',
    metadata: {
      method: PaymentMethodType.CREDIT_CARD,
      name: 'Design Deposit',
      type: PaymentType.DESIGN_DEPOSIT,
    },
  }

  const primaryDesigner = designer || tradeDesigners?.[0]
  const meeting = {
    calendlyUrl: `https://calendly.com/${primaryDesigner?.data.calendlyId}/${
      primaryDesigner?.data.calendlyUrlSlugProjectConsultation ||
      'project-consultation'
    }`,
    duration: meetingTypeDuration[MeetingTypeProject.PROJECT_CONSULTATION],
    metadata: {
      type: MeetingTypeProject.PROJECT_CONSULTATION,
      name: meetingTypeName[MeetingTypeProject.PROJECT_CONSULTATION],
      recording: '',
    },
    startTime: moment().subtract(1, 'days').toDate(),
  }

  const [createProject] = useMutation<
    CreateProjectPayload,
    CreateProjectVariables
  >(CREATE_PROJECT, {
    onCompleted: () => {
      onCompleted?.(state.email)
    },
    onError: ({ message }) => {
      notifyError(message)
      setLoading(false)
    },
  })

  const onPay = async (paymentSource?: PaymentSource) => {
    setLoading(true)
    await createProject({
      variables: {
        data: {
          data: {
            projectType: ProjectType.TRADE,
          },
          meetings: {
            create: meeting,
          },
          owners: {
            create: [
              {
                isSubscribed: true,
                isPrimary: true,
                userRole: ProjectUserRoleTrade.ARCHITECT,
                user: {
                  create: {
                    data: {
                      inTradeProgram: true,
                    },
                    email: state.email,
                    type: UserTypeTrade.ARCHITECT,
                  },
                },
              },
            ],
          },
          payments: {
            create: {
              ...payment,
              stripeSourceId: paymentSource?.id,
            },
          },
          prospects: prospect
            ? {
                create: [{ prospect: { connect: { id: prospect.id } } }],
              }
            : undefined,
          staff: primaryDesigner
            ? {
                create: [
                  {
                    isSubscribed: true,
                    isPrimary: true,
                    staffMemberRole: ProjectStaffRole.TRADE_DESIGNER,
                    staffMember: {
                      connect: {
                        id: primaryDesigner.id,
                      },
                    },
                  },
                ],
              }
            : undefined,
        },
      },
    })
    setLoading(false)
  }

  return (
    <>
      <StyledForm>
        <Form.Input
          {...(state.emailInputError && {
            error: {
              content: (
                <p className="red small">Please enter a valid email address</p>
              ),
            },
          })}
          label="Email"
          name="email"
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => {
            setState({
              ...state,
              email: value.toLowerCase(),
              emailInputError: false,
            })
          }}
          onBlur={() =>
            setState({
              ...state,
              emailInputError: !emailIsValid(state.email),
            })
          }
          placeholder="Email"
          required
          value={state.email}
        />
      </StyledForm>
      <Checkout
        buttonText="Pay and Start Project"
        disabled={!emailIsValid(state.email)}
        footnote="This deposit is redeemable against any purchase"
        includeInvoice={false}
        includeTermsAndConditions
        loading={loading}
        onPay={onPay}
        summary={<PaymentCard payment={payment} />}
        summaryTitle="Payment Request"
      />
    </>
  )
}

export default StartProjectTradeCheckout
