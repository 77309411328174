import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Timeline from 'components/shared/timeline'
import { TIMELINE_STEPS_ARRAY } from 'components/shared/timeline/steps'
import LeadTimeProvider from 'context/lead-times/provider'
import useProspect from 'context/prospect/use'

const Module = (_: RouteComponentProps) => {
  const { prospect, isTradeProspect } = useProspect()

  return (
    <Timeline
      isTradeProject={isTradeProspect}
      steps={TIMELINE_STEPS_ARRAY}
      useWeeks
      zipCode={prospect?.zipCode}
    />
  )
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <Module />
    </LeadTimeProvider>
  )
}
