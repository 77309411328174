import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProspect from 'context/prospect/use'
import TimelineCard from 'design-system/components/guide/timeline-card'
import { Section } from 'design-system/components/guide/utils'

const Timeline = () => {
  const { prospect } = useProspect()

  return (
    <Section id="timeline">
      <Grid stackable centered>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={6} tablet={16} mobile={16}>
            <p className="subtitle light-gray">Timeline</p>
            <h3 className="gray">
              Do you have a timeline you're working with?
            </h3>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={10} tablet={16} mobile={16}>
            <TimelineCard
              postalCode={prospect?.zipCode}
              url={`/welcome/${prospect?.id}/timeline`}
              useWeeks
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Timeline
