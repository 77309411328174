import { useContext } from 'react'

import { EstimateContext } from './provider'

const useEstimate = () => {
  const {
    dispatch,
    loadingUpgrades,
    nobiliaFeatures,
    sceneRelationshipGraph,
    sceneState,
    state,
    updateUpgrades,
    upgrades,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    volumes,
  } = useContext(EstimateContext)

  return {
    dispatch,
    loadingUpgrades,
    nobiliaFeatures,
    sceneRelationshipGraph,
    sceneState,
    state,
    updateUpgrades,
    upgrades,
    viewBoxBase,
    viewBoxPanX,
    viewBoxPanY,
    volumes,
  }
}

export default useEstimate
