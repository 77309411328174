import React from 'react'

import { useMedia } from 'react-use'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Colors, Breakpoint } from 'styles/app/system'

export type PriceCardProps = {
  description: string
  mostPopular?: boolean
  onClick: () => void
  price: string
  title: string
}

const PriceCard = ({
  description,
  mostPopular,
  onClick,
  title,
  price,
}: PriceCardProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <Card>
      {!isMobile && (
        <h3 className="price-label no-margin">
          <span>$</span> {price}
        </h3>
      )}

      <div className="content">
        {!isMobile && (
          <Title>
            <p className="subtitle no-margin">{title}</p>
            {mostPopular && (
              <MostPopular>
                <p>Most Popular</p>
              </MostPopular>
            )}
          </Title>
        )}

        {isMobile && (
          <>
            {mostPopular && (
              <MostPopular>
                <p>Most Popular</p>
              </MostPopular>
            )}
            <MobileTitle>
              <p className="subtitle no-margin">{title}</p>
              <h3 className="price-label no-margin">
                <span>$</span> {price}
              </h3>
            </MobileTitle>
          </>
        )}

        <p>{description}</p>

        <Button kind="ghost" text="View Details" onClick={onClick} />
      </div>
    </Card>
  )
}

export default PriceCard

const Card = styled.div`
  align-items: center;
  background: ${Colors.white};
  border: 1px solid ${Colors.blue100};
  border-radius: 4px;
  box-shadow: 0px 4px 15px rgba(120, 113, 108, 0.1);
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 555px;
  padding: 22px;
  text-align: left;
  transition: all 0.3s ease;
  width: 100%;

  .subtitle {
    color: ${Colors.blue800};
  }

  .price-label {
    color: ${Colors.blue900};
    display: flex;
    align-items: baseline;
    gap: 6px;

    span {
      color: ${Colors.blue700};
      font-size: 22px;
    }
  }

  @media ${Breakpoint.onlyMobile} {
    gap: 0px;

    div.content {
      width: 100%;
    }
  }
`

const MobileTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  gap: 17px;
  margin-bottom: 10px;

  @media ${Breakpoint.onlyMobile} {
    margin: 0;
  }
`

const MostPopular = styled.div`
  background: #e5edd3;
  border-radius: 2px;
  padding: 0 10px;
  width: fit-content;

  p {
    font-size: 14px;
  }
`
