import React from 'react'

import { Menu } from 'semantic-ui-react'

import Button from 'design-system/components/button'
import HeaderContainer, {
  FormLogoItem,
} from 'design-system/containers/header/header'

export default ({
  buttonText = 'Exit',
  noExit,
  onBack,
  onBackText = 'Back',
  onExit,
}: {
  buttonText?: string
  noExit?: boolean
  onBack?: () => Promise<void>
  onBackText?: string
  onExit?: () => Promise<void>
}) => {
  return (
    <HeaderContainer
      leftItems={
        <>
          {onBack ? (
            <Button
              onClick={onBack}
              color="gray"
              fontAwesomeIcon="arrow-left"
              iconAnimation="left"
              iconPosition="left"
              kind="text"
              text={onBackText}
            />
          ) : (
            <FormLogoItem />
          )}
        </>
      }
      rightItems={
        <>
          {!noExit && (
            <Menu.Menu position="right" style={{ alignItems: 'center' }}>
              <Button
                kind="text"
                color="dark"
                fontAwesomeIcon="close"
                text={buttonText}
                onClick={onExit || (() => history.back())}
              />
            </Menu.Menu>
          )}
        </>
      }
    />
  )
}
