import { Progress } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'

export const ProgressBar = styled(Progress)`
  &&& {
    background: ${Colors.gray100};
    height: 3px;
    left: 0;
    margin: 0 !important;
    margin-top: 61px !important;
    position: fixed;
    top: 0px;
    transition: all 0.5s ease-in-out;
    width: 100%;
    z-index: 2;

    .bar {
      background: ${Colors.black};
      height: 3px;
      min-width: 2px;
    }
  }
`
