import React, { useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { isEqual } from 'lodash'

import Accordion, {
  AcordionItemChildrenProps,
} from 'components/welcome/estimate/accordion'
import useEstimate from 'context/estimate/use'
import { notifyError } from 'context/notifications/trigger'
import useProspect from 'context/prospect/use'
import { Modal } from 'design-system/components/modal'
import {
  UpdateProspectPayload,
  UpdateProspectVariables,
  UPDATE_PROSPECT,
} from 'queries/prospect'
import { TemplateParameters } from 'types/volume'

import CabinetHeight from './accordion-items/cabinet-height'
import Dimensions from './accordion-items/dimensions'
import IntegratedLightning from './accordion-items/integrated-lighting'
import Layout from './accordion-items/layout'
import Style from './accordion-items/style'
import UpperCabinets from './accordion-items/upper-cabinets'

type RedoModalProps = {
  handleClose: () => void
  open: boolean
}

const RedoModal = ({ handleClose, open }: RedoModalProps) => {
  const { dispatch, state, updateUpgrades } = useEstimate()
  const { prospect } = useProspect()
  const [redoState, setRedoState] = useState<TemplateParameters>(state)
  const [disableClickOutside, setDisableClickOutside] = useState(false)
  const accordionItems = useMemo(
    () => [
      {
        title: 'Layout',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <Layout
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
      {
        title: 'Dimensions',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <Dimensions
            redoState={redoState}
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
      {
        title: 'Cabinet Height',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <CabinetHeight
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
      {
        title: 'Front Style',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <Style
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
      {
        title: 'Upper Cabinets',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <UpperCabinets
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
      {
        title: 'Integrated Lighting',
        content: ({ setActiveFilter }: AcordionItemChildrenProps) => (
          <IntegratedLightning
            setActiveFilter={setActiveFilter}
            setRedoState={setRedoState}
          />
        ),
      },
    ],
    [setRedoState, redoState],
  )

  const [updateProspect, { loading }] = useMutation<
    UpdateProspectPayload,
    UpdateProspectVariables
  >(UPDATE_PROSPECT, {
    onCompleted: () => {
      dispatch({
        type: 'set_estimate',
        estimate: redoState,
      })
      handleClose()
      document
        .getElementById('resultsContainer')
        ?.scrollTo({ top: 0, behavior: 'smooth' })
    },
    onError: () => {
      notifyError('Oops! Something went wrong. Please try again.')
      setDisableClickOutside(true)
      setTimeout(() => setDisableClickOutside(false), 3000) // Toast duration
    },
  })

  const handleRedoEstimate = async () => {
    await Promise.all([
      updateProspect({
        variables: {
          data: {
            data: {
              ...prospect?.data,
              estimate: redoState,
            },
          },
          where: {
            id: prospect?.id ?? '',
          },
        },
      }),
      updateUpgrades(redoState),
    ])
  }

  const handleCancel = () => {
    handleClose()
    setRedoState(state)
  }

  useEffect(() => {
    if (open && !isEqual(state, redoState)) {
      setRedoState(state)
    }
  }, [open])

  return (
    <Modal
      size="large"
      open={open}
      onClose={handleCancel}
      saveButton={{
        onClick: handleRedoEstimate,
        loading,
        text: 'View Estimate',
      }}
      cancelButton={{
        onClick: handleCancel,
        text: 'Cancel',
        loading,
      }}
      disableBackdropClick={disableClickOutside}
      zIndex={11}
    >
      <Accordion items={accordionItems} />
    </Modal>
  )
}

export default RedoModal

export type AccordionItemProps = {
  redoState?: TemplateParameters
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>
  setRedoState: React.Dispatch<React.SetStateAction<TemplateParameters>>
}
