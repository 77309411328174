import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import useEstimate from 'context/estimate/use'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import * as EstimatorData from 'views/welcome/prospect/estimate/data'

import { AccordionItemProps } from '../redo-modal'

const UpperCabinets = ({
  setActiveFilter,
  setRedoState,
}: AccordionItemProps) => {
  const { state } = useEstimate()
  const [active, setActive] = useState(state.upperStorage)

  const handleChange = (
    option: (typeof EstimatorData.upperStorageOptions)[0],
  ) => {
    setRedoState((prev) => ({
      ...prev,
      upperStorage: option.value,
    }))
    setActiveFilter(option.label)
    setActive(option.value)
  }

  useEffect(() => {
    setActiveFilter(
      EstimatorData?.upperStorageOptions?.find(
        (o) => o.value === state.upperStorage,
      )?.label ?? '',
    )
  }, [])

  return (
    <Grid padded>
      <Grid.Row stretched>
        {EstimatorData.upperStorageOptions.map((o) => (
          <Grid.Column
            key={o.label}
            widescreen={3}
            largeScreen={3}
            computer={4}
            tablet={5}
            mobile={8}
            style={{ marginBottom: '24px' }}
          >
            <SelectImageCard
              onClick={() => handleChange(o)}
              image={`${process.env.GATSBY_CDN_ROOT}/images/estimate/${o.imageUrl}`}
              text={o.label}
              selected={o.value === active}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default UpperCabinets
