import React, { useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import Button, { ButtonProps } from 'design-system/components/button'
import { Hexagon } from 'styles/app/components/icon'
import { Breakpoint, Colors } from 'styles/app/system'

type Cardtype = 'toDo' | 'complete' | 'dark'

interface CardProps {
  disabled?: boolean
  buttonText?: string
  image: string
  cardType: Cardtype
  numberStep?: string
  onClick: () => void
  title: string
}

interface CardStyles {
  button?: React.ReactNode
  buttonProps: ButtonProps
  cardButtonText?: string
  hexagonBackground: string
  hexagonColor: string
  hexagonContent: React.ReactNode
}

const FormCard = ({
  disabled,
  buttonText,
  image,
  cardType,
  numberStep,
  onClick,
  title,
}: CardProps) => {
  const { current: mapCardTypeToStyles } = useRef<Record<Cardtype, CardStyles>>(
    {
      toDo: {
        buttonProps: { color: 'dark', kind: 'solid', size: 'fluid' },
        cardButtonText: 'Start',
        hexagonBackground: Colors.gray300,
        hexagonColor: Colors.black,
        hexagonContent: <p className="subtitle">{numberStep}</p>,
      },
      complete: {
        buttonProps: { color: 'gray', kind: 'ghost', size: 'fluid' },
        cardButtonText: 'Review',
        hexagonBackground: Colors.greenBright,
        hexagonColor: Colors.white,
        hexagonContent: <FontAwesomeIcon icon={['fal', 'check']} />,
      },
      dark: {
        buttonProps: {
          color: 'dark',
          iconPosition: 'left',
          kind: 'solid',
          size: 'fluid',
        },
        cardButtonText: 'Start',
        hexagonBackground: Colors.gray500,
        hexagonColor: Colors.white,
        hexagonContent: <FontAwesomeIcon icon={['fal', 'flag-pennant']} />,
      },
    },
  )

  return (
    <StyledCard
      onClick={disabled ? undefined : onClick}
      styled={{ cardType, disabled }}
    >
      <Hexagon
        className="hexagon"
        styled={{
          background: mapCardTypeToStyles[cardType].hexagonBackground,
          color: mapCardTypeToStyles[cardType].hexagonColor,
          fontSize: '16px',
          size: '33px',
        }}
      >
        {mapCardTypeToStyles[cardType].hexagonContent}
      </Hexagon>
      <div className="content">
        <Image
          src={
            image ||
            'https://via.placeholder.com/150x120/F6F5F4/A8A29E/?text=placeholder'
          }
        />
        <h4 className="no-margin">{title}</h4>
      </div>
      <Button
        {...(mapCardTypeToStyles[cardType].buttonProps as any)}
        disabled={disabled}
        fontAwesomeIcon={disabled ? 'lock-keyhole' : undefined}
        style={{ marginTop: '15px' }}
        text={buttonText ?? mapCardTypeToStyles[cardType].cardButtonText}
      />
    </StyledCard>
  )
}

const StyledCard = styled.div<{
  styled?: { cardType: string; disabled?: boolean }
}>`
  background-color: ${({ styled }) =>
    styled?.cardType === 'dark' ? Colors.gray200 : 'white'};
  border-radius: 8px;
  border: ${({ styled }) =>
    styled?.cardType === 'complete' ? `1px solid ${Colors.green600}` : 'none'};
  background-image: ${({ styled }) =>
    styled?.cardType === 'complete'
      ? 'none'
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};
  cursor: pointer;
  display: flex;
  filter: drop-shadow(0px 0px 15px rgba(120, 113, 108, 0.09));
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 310px;
  padding: 16px;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${({ styled }) =>
    styled?.disabled &&
    css`
      box-shadow: none;
      background-color: ${styled?.cardType === 'dark'
        ? Colors.gray100
        : 'white'};
      pointer-events: none;
    `}

  @media ${Breakpoint.onlyComputer} {
    ${({ styled }) =>
      !styled?.disabled &&
      css`
        :hover {
          transform: translate(0px, -5px);
          box-shadow: 0px 7px 15px rgba(120, 113, 108, 0.2);

          button {
            background-color: ${mapCardTypeToHoverColor[
              styled?.cardType as string
            ].hoverBackground} !important;
            color: ${mapCardTypeToHoverColor[styled?.cardType as string]
              .hoverColor} !important;
          }
        }
      `}
  }

  @media ${Breakpoint.downFromComputer} {
    margin: 0 10px 20px;
  }

  div.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 170px;
    position: relative;

    img {
      height: 160px;
      margin-bottom: 16px;
      max-height: 160px;
    }

    h4 {
      color: ${({ styled }) =>
        styled?.cardType === 'dark' && styled?.disabled
          ? Colors.gray500
          : styled?.cardType === 'dark'
          ? Colors.gray600
          : Colors.black};
    }
  }

  div.hexagon {
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-40%, -35%);

    &.complete {
      background: ${Colors.greenBright};
      border: none;
      color: white;
    }
  }
`

const mapCardTypeToHoverColor: Record<
  string,
  { hoverBackground: string; hoverColor: string }
> = {
  toDo: {
    hoverBackground: Colors.gray600,
    hoverColor: Colors.white,
  },
  complete: {
    hoverBackground: Colors.gray100,
    hoverColor: Colors.gray800,
  },
  dark: {
    hoverBackground: Colors.gray500,
    hoverColor: Colors.white,
  },
}

export default FormCard
