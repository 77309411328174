import React, { useEffect } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import FileCard from 'components/welcome/prospect/project-file/file-card'
import UploadFileCard from 'components/welcome/prospect/project-file/upload-file-card'
import useProjectFileMutate from 'context/project-file/use-mutate-no-context'
import useProspect from 'context/prospect/use'
import useProspectMutateNoContext from 'context/prospect/use-mutate-no-context'
import fireEvent from 'context/tracking/events'
import SaveToast from 'design-system/components/save-toast/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { FormContactSource } from 'types/crm'
import { ProjectFile, ProjectFileType } from 'types/project-file'

const UploadFloorPlan = (_: RouteComponentProps) => {
  const { prospect, refetch, prospectUploads } = useProspect()
  const { deleteProjectFile } = useProjectFileMutate()
  const { upsertProspect } = useProspectMutateNoContext()

  useEffect(() => {
    if (!prospect?.id) refetch()
  }, [prospect])

  return (
    <AppLayout>
      <Grid stackable centered>
        <StepTitle
          title="Show us your space"
          subTitle="To provide relevant recommendations, we need to understand your space."
        />
        <Grid.Row centered>
          <Grid.Column textAlign="center">
            <p>
              Please upload photos of your space or a floor plan—check our easy
              and quick guide on{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="https://formkitchens.com/blog/article/how-to-create-floor-plan/"
                target="_blank"
              >
                how to create one
              </a>
              .
            </p>
            <p>
              If you need help please text us at{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="sms:+14154293515"
                target="_blank"
              >
                (415) 429-3515
              </a>
              .
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched style={{ rowGap: '28px' }}>
          <Grid.Column largeScreen={3} computer={4} tablet={8} mobile={16}>
            <UploadFileCard
              fileType={ProjectFileType.FLOOR_PLAN}
              onFilesUploaded={async (_: string[]) => {
                if (!prospect?.data.floorPlanCompletedAt) {
                  fireEvent({
                    type: 'file_uploaded',
                    fileType: 'Floor Plan',
                  })
                  await upsertProspect({
                    variables: {
                      data: {
                        email: prospect?.email,
                        data: {
                          ...prospect?.data,
                          floorPlanCompletedAt: new Date().toISOString(),
                          form_contact_source: FormContactSource.FLOOR_PLAN,
                        },
                      },
                    },
                  })
                }
                refetch()
              }}
              prospectId={prospect?.id ?? ''}
            />
          </Grid.Column>
          {prospectUploads?.map((f: ProjectFile) => (
            <Grid.Column
              key={f.id}
              largeScreen={3}
              computer={4}
              tablet={8}
              mobile={16}
            >
              <FileCard
                file={f}
                onFileRemoved={async (id?: string) => {
                  if (!id) return
                  await deleteProjectFile({
                    variables: {
                      where: {
                        id,
                      },
                    },
                  })
                  refetch()
                }}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <div style={{ margin: '20px 0' }}>
        <SaveToast
          disabled={!prospectUploads}
          mainButtonText="Complete"
          message="Finished uploading?"
          onClick={() => {
            navigate(`/welcome/${prospect?.id}/`)
          }}
          show={!!prospectUploads.length}
        />
      </div>
    </AppLayout>
  )
}

export default UploadFloorPlan
