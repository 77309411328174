import React, { useState } from 'react'

import { Form, Grid } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import { useZipCodes } from 'context/zip-codes/provider'
import { StyledForm } from 'styles/app/components/form'
import { Prospect } from 'types/prospect'
import { UserTypeTrade } from 'types/user'
import { emailIsValid, getDropdownOptionsFromEnum } from 'views/utils'

const ContactInformationForm = ({
  isEmailDisabled,
  setState,
  state,
}: {
  isEmailDisabled: boolean
  setState: React.Dispatch<React.SetStateAction<Partial<Prospect>>>
  state: Partial<Prospect>
}) => {
  const { fetchUniqueZipCode } = useZipCodes()
  const [inputError, setInputError] = useState<{
    email: boolean
    zipCode: boolean
    unreachableZipCode: boolean
  }>({
    email: false,
    zipCode: false,
    unreachableZipCode: false,
  })

  const isZipCodeReachable = async () => {
    const res = await fetchUniqueZipCode({
      variables: { where: { zipCode: state.zipCode as string } },
    })
    if (!res.data?.zipCode)
      setInputError({ ...inputError, unreachableZipCode: true })
    else setInputError({ ...inputError, unreachableZipCode: false })
  }

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column computer={9} tablet={16}>
          <StyledForm>
            <Form.Group widths="equal">
              <Form.Input
                label="First Name"
                name="firstName"
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) =>
                  setState({
                    ...state,
                    firstName: value,
                  })
                }
                placeholder="First Name"
                required
                value={state.firstName}
              />
              <Form.Input
                label="Last Name"
                name="lastName"
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) =>
                  setState({
                    ...state,
                    lastName: value,
                  })
                }
                placeholder="Last Name"
                required
                value={state.lastName}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                {...(inputError.email && {
                  error: {
                    content: (
                      <p className="red small">
                        Please enter a valid email address
                      </p>
                    ),
                  },
                })}
                disabled={isEmailDisabled}
                label="Company Email"
                name="email"
                type="email"
                onBlur={() => {
                  if (!emailIsValid(state.email ?? ''))
                    setInputError({ ...inputError, email: true })
                  else setInputError({ ...inputError, email: false })
                }}
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) =>
                  setState({
                    ...state,
                    email: value,
                  })
                }
                placeholder="Company Email"
                required
                value={state.email}
              />
              <Form.Select
                label="Role"
                onChange={(_, { value }) =>
                  setState({
                    ...state,
                    data: {
                      userType: value as UserTypeTrade,
                    },
                  })
                }
                options={getDropdownOptionsFromEnum(UserTypeTrade)}
                placeholder="Select a role"
                required
                value={state.data?.userType}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                {...(inputError.zipCode && {
                  error: {
                    content: (
                      <p className="red small">Please enter a valid zip code</p>
                    ),
                  },
                })}
                label="Company Zip Code"
                name="zipcode"
                type="zipcode"
                onBlur={() => {
                  if (!/^(\d{9}|\d{5})$/.test(String(state.zipCode)))
                    setInputError({ ...inputError, zipCode: true })
                  else {
                    setInputError({ ...inputError, zipCode: false })
                    isZipCodeReachable()
                  }
                }}
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) =>
                  setState({
                    ...state,
                    zipCode: value,
                  })
                }
                placeholder="Company Zip Code"
                required
                value={state.zipCode}
                width={8}
              />
            </Form.Group>
          </StyledForm>
          {inputError.unreachableZipCode && (
            <HelpMessage
              icon="circle-info"
              title="We don't deliver here yet"
              message="Sorry, we don’t currently deliver to your zip code yet. You can receive our orders anywhere in the continental US and handle transportation from there. Email hello@formkitchens.com for more information."
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ContactInformationForm
