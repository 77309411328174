import React from 'react'

import { Container, Grid } from 'semantic-ui-react'

import PriceDetails from './price-details/price-details'
import WhatsIncluded from './whats-included'

const DetailsSection = () => {
  return (
    <Container>
      <Grid>
        <Grid.Row reversed="computer">
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <PriceDetails />
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <WhatsIncluded />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default DetailsSection
